import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { AppStateService } from 'src/app/services/init/app-state.service';

@Component({
  selector: 'app-table-details',
  templateUrl: './table-details.component.html',
  styleUrls: ['./table-details.component.scss'],
})
export class TableDetailsComponent implements OnInit {
  popoverOptions = {
    showBackdrop: false,
    dismissOnSelect: true,
    backdropDismiss: true,
    keepContentsMounted: true,
    side: "bottom",
    alignment: "start",
    mode: "md",
    size: "cover",
  }
  reaasignObj = {
    newTableSelect: '',
    reaasignPax: '',
    reaasgignReason: ''
  }
  allotType: string = '';
  isHide: boolean = false;
  titleText: string;
  items: FormArray;
  public myIndex: number = 0;
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() sendDataforDashboard = new EventEmitter();
  @Input() joinApiDetails: any
  @Input() joinModalValue: any;
  tableData: any
  joinMoreform: FormGroup;
  joinList: any = []
  paxArray: any = [];
  appstateStorage: any;
  disableJoinMore: boolean = true;
  enableButton: boolean = false;
  sendAllPrimaryTable: any;
  joinedTableArrLength: any;
  tablePaxBind: any;
  joinTablePaxValue: number = 0


  constructor(
    public modalCtrl: ModalController,
    public dashboardService: DashboardhomeService,
    public common: CommonService,
    private formBuilder: FormBuilder,
    private appstate: AppStateService
  ) {

  }

  ngOnInit() {
    console.log("joinApiDetails --> ", this.joinApiDetails);
    
    this.joinMoreform = new FormGroup({
      items: new FormArray([]),
      firstTable: new FormControl(''),
      firstPax: new FormControl('')
    });
    this.appstateStorage = this.appstate.getAppData()

    console.log(" this.appstateStorage", this.appstateStorage)

  }

  get firstTable(): any {
    return this.joinMoreform.get('firstTable');
  }
  get firstPax(): any {
    return this.joinMoreform.get('firstPax');
  }
  get itemsForms() {
    return this.joinMoreform.get('items') as FormArray;
  }

  ngOnChanges() {
    console.log("joinModalValue", this.joinModalValue)
    if (!this.joinModalValue) {
      document.getElementById('joinModalClose').style.display = 'none';
    } else {
      document.getElementById('joinModalClose').style.display = 'block';

      let arr = []
      this.joinApiDetails.vacantTable.forEach(element => {
        if (element.pdr == false) {
          element["tableStatus"] = 'available'
          element["tableindetifier"] = ''
          arr.push(element)
        }
      })
      this.joinApiDetails.vacantTable = arr
      let newJoin = []
      this.sendAllPrimaryTable = this.joinApiDetails.joinedTableArr

      this.joinApiDetails.joinedTableArr.forEach(element => {

        if (element.tableNum != this.joinApiDetails.tableNumber) {
          newJoin.push(element)
        }
      });
      this.joinApiDetails.joinedTableArr = newJoin
      this.joinedTableArrLength = this.joinApiDetails.joinedTableArr.length + 1;
      console.log(" this.joinApiDetails", this.joinApiDetails)
      console.log(" this.sendAllPrimaryTable", this.sendAllPrimaryTable)
      if(this.sendAllPrimaryTable.length) {
        this.sendAllPrimaryTable.forEach(element => {
          this.joinTablePaxValue = this.joinTablePaxValue + element.allocatedPax
        });
      }
     


      this.getingJoinApi()


    }
  }

  ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  getingJoinApi() {
    console.log("getingJoinApi");
    this.dashboardService.getTableUser(this.joinApiDetails).subscribe(res => {
      if (res) {
        this.tableData = res
        this.joinMoreform.get('firstTable').setValue(this.joinApiDetails.tableNumber);

        if (this.tableData.resPax <= this.tableData.totalPax) {
          this.tablePaxBind = this.tableData.resPax
          console.log("hittttttt")
          this.disableJoinMore =   true
        } else {
          this.tablePaxBind = this.tableData.totalPax
          this.disableJoinMore = false
          console.log(" else hittttttt")
        }
        let paxCount = this.tablePaxBind
        if (this.joinApiDetails.joinedTableArr.length!=0) {
          this.joinApiDetails.joinedTableArr.forEach(element => {
            paxCount = paxCount + element.resPax
          });
        
        if (paxCount == this.tableData.resPax) {
          console.log(" wweeee")
          this.disableJoinMore = false
        } else {
          console.log(" 53645364")
          this.disableJoinMore = true
          
        }
      }
    
        this.joinMoreform.patchValue({
          "firstPax": this.tablePaxBind
        })

        if(this.joinApiDetails?.joinedTableArr[0]?.isJoined == true ) {
          console.log("inside")
          this.joinMoreform.patchValue({
            "firstPax":this.tableData.tablePax
          })
         
          if(this.tableData.resPax <= this.joinTablePaxValue){
            this.disableJoinMore =   true
        } else {
          console.log("prince=NEw " , this.joinTablePaxValue)
          this.tablePaxBind = this.joinTablePaxValue
          this.disableJoinMore =   false
        }
      }
   

      }
    }, err => {
      this.common.errorHandler(err);
    })
  }


  closeModal() {
    this.joinModalValue = false
    this.isHide = false;
    this.sendDataforDashboard.emit(this.joinModalValue)
    document.getElementById('joinModalClose').style.display = 'none';
  }

  openAllote(allotType) {
    this.isHide = true;
    this.allotType = allotType;
    if (allotType === 'isReassign') {
      this.titleText = `Re-assign Table ${this.joinApiDetails.tableNumber}`;
    }
    if (allotType === 'isJoin') {
      // this.joinMorefun()
      this.titleText = 'Join Tables';
    }

  }



  reAssignApi() {
    this.dashboardService.postReaasign(this.reaasignObj, this.joinApiDetails, this.tableData.memberId).subscribe(res => {
      if (res) {
        document.getElementById('joinModalClose').style.display = 'none';
        this.joinModalValue = false
        this.isHide = false;
        localStorage.setItem('RestaurantView',"true");
        this.sendDataforDashboard.emit(this.joinModalValue)
        this.common.customAlertReload(res.statusMessage)


      }
    }, err => {
      this.common.errorHandler(err);
    })
  }

  joinMorefun() {
      if(this.items?.value.length != 2) {
        console.log("here")
        this.disableJoinMore = true
      }
    this.enableButton = true
    this.joinList.push({
      "index": null,
      "tableNo": null
    })
    this.items = this.joinMoreform.get('items') as FormArray;
    this.items.push(this.createItem());
    if (this.joinApiDetails.vacantTable.length <= this.appstateStorage.appInitData.dataSettings.joinMoreLimit) {
      if (this.joinApiDetails.vacantTable.length == this.items.length) {
        this.disableJoinMore = true;
      }
    } else {
      if (this.items.length <= this.appstateStorage.appInitData.dataSettings.joinMoreLimit) {
        if (this.appstateStorage.appInitData.dataSettings.joinMoreLimit == this.items.length) {
          this.disableJoinMore = true;
        }
      }
    }


  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      moreTable: ['', [Validators.required]],
      moreTablePax: ['', [Validators.required]],
    });

  }

  JoinAPI() {
    this.common.presentLoader()
    let arr1 = []
    if (this.sendAllPrimaryTable.length == 0) {
      arr1.push({
        'moreTable': {
          'tableSfid': this.joinApiDetails.tableSelectData.tableSfid
        },
        'moreTablePax': this.tablePaxBind
      })
      console.log("hit")
    } else {
      this.sendAllPrimaryTable.forEach(element1 => {
        arr1.push({
          'moreTable': {
            'tableSfid': element1.tableSfid
          },
          'moreTablePax': element1.allocatedPax
        })
      });

    }
    let sendArry = []
    sendArry = [...this.items.value, ...arr1]
    console.log("sendArry", sendArry)
    this.dashboardService.postJoinMore(this.joinApiDetails, sendArry, this.tableData).subscribe(res => {
      if (res) {
        this.common.dismissLoader()
        document.getElementById('joinModalClose').style.display = 'none';
        this.joinModalValue = false
        this.isHide = false;
        this.sendDataforDashboard.emit(this.joinModalValue)
        localStorage.setItem('RestaurantView',"true");
        this.common.customAlertReload(res.statusMessage)


      }
    }, err => {
      this.common.dismissLoader()
      this.common.errorHandler(err);
    })
  }

  joinTableSelect(table, index) {
    this.joinList[index].tableNo = table.detail.value.tableNumber;
    this.joinApiDetails.vacantTable.forEach((element, j) => {
      if (table.detail.value.tableNumber == element.tableNumber) {
        element['tableStatus'] = 'occupied';
        element['tableindetifier'] = table.detail.value.tableNumber;
      } else {
        let existValue = this.items.value.findIndex(x =>
          x.moreTable.tableNumber == element.tableNumber
        )
        if (existValue == -1) {
          this.joinApiDetails.vacantTable[j]['tableStatus'] = 'available'
          this.joinApiDetails.vacantTable[j]['tableindetifier'] = ''

        }
      }
    });
    this.items.controls[index].patchValue({ 'moreTablePax': '' })
  }

  joinTabePax() {
    console.log("paxx select", this.items.value)
    console.log(" this.joinApiDetails",  this.joinApiDetails)
    let tableAvailable = 0
    this.joinApiDetails.vacantTable.forEach(element => {
      if(element.tableStatus == 'available'){
        tableAvailable = tableAvailable + 1;
      }
    });
    console.log("available->>",tableAvailable)
    let paxCount = this.tablePaxBind
    console.log("before  pax", paxCount)
    this.items.value.forEach(element => {
        paxCount = paxCount + element.moreTablePax
      });
      console.log("paxCount after pax", paxCount)
      if(tableAvailable == 0){
        console.log("not availble")
        this.disableJoinMore = true
      }else{
        if (paxCount >= this.tableData.resPax) {
          console.log("-------")
          this.disableJoinMore = true
        } else {
          console.log("++++++++")
          this.disableJoinMore = false
        }

      }
  
  }

  reasignTable(reasignTableValue) {
    this.reaasignObj.reaasignPax = ''
    this.createArray(reasignTableValue.detail.value.minPax, reasignTableValue.detail.value.maxPax)

  }

  createArray(min, max) {
    this.paxArray = []
    for (let i = min; i <= max; i++) {
      this.paxArray.push(i)
    }
    return this.paxArray
  }

  remove(getIndex) {
    const index = this.joinApiDetails.vacantTable.findIndex(item => item.tableNumber === this.joinList[getIndex].tableNo)
    if (index !== -1) {
      this.joinApiDetails.vacantTable[index]['tableStatus'] = 'available'
      this.joinApiDetails.vacantTable[index]['tableindetifier'] = ''
    }
    let tableAvailable = 0
    this.joinApiDetails.vacantTable.forEach(element => {
      if(element.tableStatus == 'available'){
        tableAvailable = tableAvailable + 1;
      }
    });
    this.joinList.splice(getIndex, 1);
    this.items.removeAt(getIndex)
    this.disableJoinMore = false;
    if (this.items.length == 0) {
      this.enableButton = false;
    } else {
      this.enableButton = true;
    }
    this.joinTabePax()
  }

}
