import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { CommonService } from 'src/app/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { ALLOW_OTP, COUNT } from 'src/app/app.constants';
import { InitService } from 'src/app/services/init/init.service';

@Component({
  selector: 'app-allow-otp',
  templateUrl: './allow-otp.component.html',
  styleUrls: ['./allow-otp.component.scss'],
})
export class AllowOtpComponent implements OnInit {
  brandName: { brandName: string; };

  constructor(public appState: AppStateService, public commonServ: CommonService,
    public translate: TranslateService, public initService: InitService, public activatedRoute: ActivatedRoute,
    private modalCtrl: ModalController) {
    this.translate.get(JSON.parse(localStorage.getItem('headerDetails')).brandName).subscribe((res: string) => {
      this.brandName = {
        brandName: res
      }
    });
  }

  ngOnInit() {

  }

  closeModal(val) {
    let type;
    switch (val) {
      case parseInt(COUNT.COUNT_0):
        type = ALLOW_OTP.close;
        break;
      case parseInt(COUNT.COUNT_1):
        type = ALLOW_OTP.deny;
        break;
      case parseInt(COUNT.COUNT_2):
        type = ALLOW_OTP.allow;
        break;
    }
    this.modalCtrl.dismiss(type)
  }



}
