import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { OpenReservationService } from 'src/app/services/open-reservation/open-reservation.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';
import * as phoneValidation from "libphonenumber-js";
import { ProfileService } from 'src/app/services/profile.service';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { LocalizationService } from 'src/app/services/core/localize/localization.service';
import { ModalController, Platform } from '@ionic/angular';
import { SelectMembershipComponent } from 'src/app/modals/select-membership/select-membership.component';
import * as _ from "lodash";
import { EventService } from 'src/app/services/event/event.service';
import { ACTIVATE_RESERVATION_PAGE, COUNT, COUNT_NUMBER, IdentifierExporterPayload, JAVASCRIPT, OFFER_EVENT_PAGE, REGEX, ReservationCelebrations, ReservationPromocodeQueryString, RESERVATION_DETAILS_MODAL, SPENDERS_PAGE, TITLE } from 'src/app/app.constants';
import { Router } from '@angular/router';
declare var $: any;
import country from '../../../data/country-code.json';
import { LocationService } from 'src/app/services/core/location/location.service';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import * as moment from 'moment';

@Component({
  selector: 'app-walkin',
  templateUrl: './walkin.component.html',
  styleUrls: ['./walkin.component.scss'],
})
export class WalkinComponent implements OnInit {
  reservationConfirmationPara: any;
  walkinForm: FormGroup;
  ionicStorage: any;
  countries: any;
  SelectCode: any;
  salutationarray: any = [];
  guestCount: number = COUNT_NUMBER.COUNT_1
  celebrations: { appBirthday: string; appAnniversary: string; appOther: string; };
  celebration: string;
  promocodeDetails: any;
  reservationDateTime: Date;
  certificateBenefits: any = [];
  cardBenefits: any = [];
  membershipNumber: any;
  memberProfileData: any;
  benefitDetails: any;
  otherCertificateArr: any = []
  showBenefitName: any;
  enableBenefitName: boolean = false;
  enableUserDetailsValue: boolean = false
  walkinapiRes: any;
  memeberApiRes: any;
  benefitExpanded: boolean = false
  loginOTPDuration: any;
  loginOTPTimeOut: any;
  brandDeeatils = JSON.parse(localStorage.getItem('headerDetails'));
  isDisabled: any;
  @Input() tableNo: any = null;
  isValidNumber: boolean = false;
  emailChek: boolean = false
  walkinRoomValue: boolean = false
  enableButton: boolean = false
  confirmationNumberPara: any;
  popoverOptions = {
    showBackdrop: false,
    dismissOnSelect: true,
    backdropDismiss: true,
    keepContentsMounted: true,
    side: "bottom",
    alignment: "start",
    mode: "md",
    size: "cover",
  };
  allVaccanTable = [];
  countryOptions = {
    header: 'Select Country Code',
  };
  salutationOptions = {
    header: 'Salutation',
  };
  verifiedPromo: boolean = false

  callingAPIProfile: boolean = false
  enableMobileFeild: boolean = false
  enableEmailInput: boolean = false
  sendType: any;
  userProfileData: any;
  callingAPIProfileByEmail: boolean = false;
  checkByMobile: boolean = false
  updateMob: any;
  benefitData: any;
  certificateType: string;
  showOneTimeBenefitWarning: boolean = true
  onetimeOutwarning:boolean = true;

  constructor(
    private utilitiesService: UtilitiesService,
    public app: AppStateService,
    public commonService: CommonService,
    public translate: TranslateService,
    private openReservationService: OpenReservationService,
    private profileService: ProfileService,
    private onBoardingService: OnboardingService,
    private localizationService: LocalizationService,
    private modalController: ModalController,
    public event: EventService,
    public router: Router,
    public plt: Platform,
    private locationService: LocationService,
    public dashboardService: DashboardhomeService,
  ) {

  }

  ngOnDestroy() {
    this.walkinForm.controls['tableNumber'].setValue('');
    this.tableNo = '';
    this.commonService.tableSfid = '';
  }


  ngOnInit() {
    this.celebrations = ReservationCelebrations;
    this.ionicStorage = this.app.getAppData();
    this.countries = this.getCurrentCountryOnTop(country)
    this.salutationarray = this.ionicStorage.appInitData.honorifics;
    this.loginOTPDuration = this.ionicStorage.appInitData.loginOTPDuration;
    this.validForm()
    if (this.tableNo) {
      console.log("if", this.tableNo);
      this.isDisabled = true;
      this.walkinForm.controls['tableNumber'].setValue(this.tableNo);
      
    }
    else {
      console.log("else", this.tableNo);
      this.isDisabled = false;
      this.walkinForm.controls['tableNumber'].setValue('');
    }
    this.walkinForm.patchValue({
      countryCodeSelected: this.countries[0]
    });

    this.countryCodeFun()
    this.allTableList()

  }

  getCurrentCountryOnTop(countries) {
    if(this.brandDeeatils.brandName =='Gourmet Club') { 
      let currentCountryCode = 'ke'
      for (let i = 0; i < countries.length; i++) {
          if (countries[i].iso2.toLowerCase() === (currentCountryCode ? currentCountryCode.toLowerCase() : 'ke')) {
              countries.unshift(countries.splice(i, 1)[0]);
              break;
          }
      }
      return countries;
    } else {
      let currentCountryCode = this.locationService.getCurrentCountry();
      for (let i = 0; i < countries.length; i++) {
          if (countries[i].iso2.toLowerCase() === (currentCountryCode ? currentCountryCode.toLowerCase() : 'in')) {
              countries.unshift(countries.splice(i, 1)[0]);
              break;
          }
      }
      return countries;
    }
}

  // ... Value get in the country drop down when user change ...//
  countryCodeFun() {
    if (this.walkinForm.value.countryCodeSelected) {
      this.SelectCode = this.walkinForm.value.countryCodeSelected.dialCode
    }
  }

  validForm() {
    this.walkinForm = new FormGroup({
      countryCodeSelected: new FormControl(this.countries[0]),
      genderCode: new FormControl(this.salutationarray[0]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.pattern(REGEX.email)]),
      mobile: new FormControl(''),
      promocode: new FormControl('', Validators.compose([Validators.pattern(new RegExp(REGEX.promocode))])),
      selectedOutlet: new FormControl(''),
      tableNumber: new FormControl('',[Validators.required]),
      chequeNumber: new FormControl(''),
      hotelComment: new FormControl(''),
      promocodeDetails: new FormControl(''),
      benefit: new FormControl('certificate'),
      memberBenefit: new FormControl(''),
      benefitType: new FormControl('',),
      walkinRoom: new FormControl(''),
    })

    this.promocode.valueChanges.subscribe((value: any) => {
      try {
        this.promocode.setValue(value.toUpperCase(), { emitEvent: false });
      } catch (e: any) {
        console.log("Value e::>", e);
      }
    });


  }

  // Get value in form 
  get genderCode(): any { return this.walkinForm.get(ACTIVATE_RESERVATION_PAGE.genderCode) as FormControl }
  get firstName(): any { return this.walkinForm.get(ACTIVATE_RESERVATION_PAGE.firstName) as FormControl }
  get lastName(): any { return this.walkinForm.get(ACTIVATE_RESERVATION_PAGE.lastName) as FormControl }
  get mobile(): any { return this.walkinForm.get(ACTIVATE_RESERVATION_PAGE.mobile) as FormControl }
  get email(): any { return this.walkinForm.get(ACTIVATE_RESERVATION_PAGE.email) as FormControl }
  get countryCodeSelected(): any { return this.walkinForm.get(ACTIVATE_RESERVATION_PAGE.countryCodeSelected) as FormControl }
  get promocode(): any { return this.walkinForm.get(ACTIVATE_RESERVATION_PAGE.promocode) as FormControl }
  get selectedOutlet(): any { return this.walkinForm.get(ACTIVATE_RESERVATION_PAGE.countryCodeSelected) as FormControl }
  get tableNumber(): any { return this.walkinForm.get(ACTIVATE_RESERVATION_PAGE.tableNumber) as FormControl }
  get chequeNumber(): any { return this.walkinForm.get(ACTIVATE_RESERVATION_PAGE.chequeNumber) as FormControl }
  get hotelComment(): any { return this.walkinForm.get(ACTIVATE_RESERVATION_PAGE.hotelComment) as FormControl }
  get walkinRoom(): any { return this.walkinForm.get('walkinRoom') as FormControl }

  ngAfterContentInit() {
    this.plt.ready().then((readySource) => {
      this.translate.get('Walk In').subscribe((res: string) => {
        this.event.publish(TITLE.TITLE, res);
      });
    });

    this.walkinForm.patchValue({
      'genderCode': this.salutationarray[0].id
    })

    this.walkinForm.patchValue({
      'selectedOutlet': this.ionicStorage.userDetails.outletName
    });

  }


  getcountryCode() {
    this.SelectCode = this.walkinForm.value.countryCodeSelected.dialCode
    this.validatePhoneNumber()
  }



  onlyNumberValidate(event: any) {
    if (event.target.value) {
      this.walkinForm.setValidators([this.utilitiesService.validatePhoneNumber]);
    } else {
      this.walkinForm.setValidators([]);
    }
    const pattern = REGEX.onlyNumberValidate;
    let curStr = event.target.value;
    let updateMob = curStr.replace(pattern, '');
    this.updateMob = curStr.replace(pattern, '');
    this.walkinForm.get('mobile').setValue(updateMob)
    this.validatePhoneNumber(event)
    this.certificateBenefits = []
    this.cardBenefits = []
    this.otherCertificateArr = []
    this.enableBenefitName = false
    this.callingAPIProfile = true
    if(this.callingAPIProfileByEmail) {
      console.log("insdie callingAPIProfileByEmail")
  }  else {
      this.walkinForm.get('email').setValue('')
      if(this.walkinForm.value.email=='') {
        if(this.tableNo=='') { 
          this.walkinForm.patchValue({
            'tableNumber':''
          })
        }
          this.walkinForm.patchValue({
            "chequeNumber": "",
            "hotelComment": "",
            "promocode": "",
            'salutation': "",
            'firstName': "",
            'lastName': "",
          })
        
   
    }
  }
    this.celebration = ""
    this.guestCount = COUNT_NUMBER.COUNT_1
  }

  onlyEmailValidate() {
    if(this.walkinForm.value.email=='') {
      this.callingAPIProfileByEmail = false
    } else {
      this.emailChek = this.walkinForm.get('email').valid;
      if(this.walkinForm.value.mobile!=''&& this.walkinForm.value.email!='' && this.checkByMobile) {
      
        this.walkinForm.patchValue({
          'firstName': '',
          'lastName': '',
          'mobile': '',
        });
        this.certificateBenefits = []
        this.cardBenefits = []
        this.otherCertificateArr = []
        this.enableBenefitName = false
        this.celebration = ""
      }
      this.commonMethod()
    }
    if(this.callingAPIProfile) {
      this.callingAPIProfile = false
      return;
    } else {
        if(this.tableNo=='') { 
          this.walkinForm.patchValue({
            'tableNumber':''
          })
        }
      
      if(this.walkinForm.value.mobile=='') {
      this.walkinForm.patchValue({
        'firstName': '',
        'lastName': '',
        'mobile': '',
      });
      this.certificateBenefits = []
      this.cardBenefits = []
      this.otherCertificateArr = []
      this.enableBenefitName = false
      this.celebration = ""
    }
      if (!this.callingAPIProfile) {
        if ( this.walkinForm.value.email  != '') {
          this.emailChek = this.walkinForm.get('email').valid;
          if (this.emailChek === true) {
            this.sendType = 'email'
            if(this.walkinForm.value.mobile=='') {
              this.callmemberMobileApi()
            } 
          }

        } else {
          this.emailChek = false
        }
      }

     else {
      this.walkinForm.value.email = ''
    }
    this.commonMethod()
    }
   
  }
  validatePhoneNumber(event?) {
    let isValidNumber;
    let stringNumber = this.walkinForm.value.mobile ? this.walkinForm.value.mobile.toString() : "";
    let upperCaseCountryCode = this.walkinForm.value.countryCodeSelected ? this.walkinForm.value.countryCodeSelected.iso2.toUpperCase() : "";
    let parsedNumber: any = phoneValidation.parseNumber(stringNumber, upperCaseCountryCode);
    isValidNumber = phoneValidation.isValidNumber(parsedNumber);
    this.isValidNumber = isValidNumber
    this.commonMethod()
    // delayDuration
    clearTimeout(this.loginOTPTimeOut);
    if (this.SelectCode in this.loginOTPDuration && isValidNumber) {

      this.loginOTPTimeOut = setTimeout(() => {
        this.sendType = 'mobile'
        if (this.walkinForm.value.email == '') {
          this.callmemberMobileApi()
        }
      }, this.loginOTPDuration[this.walkinForm.value.countryCodeSelected.dialCode]?.delayDuration * 700);
    } else {
      if (isValidNumber) {
        this.sendType = 'mobile'
        if (this.walkinForm.value.email == '') {
          this.callmemberMobileApi()
        }
       
        return null;
      }
    }

    console.log("invalid", isValidNumber)
    this.certificateBenefits = []
    this.walkinForm.patchValue({
      // "email": "",
      // 'firstName': "",
      // 'lastName': "",
    })

    this.otherCertificateArr = [];
    this.cardBenefits = []
    this.enableBenefitName = false
    return { isInvalidNumber: true };
  }


  onDecrement(outletType, member) {
    if (outletType == RESERVATION_DETAILS_MODAL.RESTAURANT) {
      if (member == RESERVATION_DETAILS_MODAL.GUEST) {
        if (this.guestCount != parseInt(COUNT.COUNT_1)) {
          this.guestCount -= parseInt(COUNT.COUNT_1);
        }
      }
    }
    // else if (outletType == RESERVATION_DETAILS_MODAL.POOL) {
    //     if (member == RESERVATION_DETAILS_MODAL.CHILD) {
    //         if (this.childCount != parseInt(COUNT.COUNT_0)) {
    //             this.childCount -= parseInt(COUNT.COUNT_1);
    //         }
    //     }
    //     else if (member == RESERVATION_DETAILS_MODAL.ADULT) {
    //         if (this.adultCount != parseInt(COUNT.COUNT_0)) {
    //             this.adultCount -= parseInt(COUNT.COUNT_1);

    //         }
    //     }
    // }
  }

  onIncrement() {
    this.guestCount += 1;
    if(this.benefitData) {
      if(this.guestCount > Number(this.benefitData?.maxAdmitLimitGuest)) {
        if(this.showOneTimeBenefitWarning) {
          document.getElementById('certifcateWarning').classList.add('show');
        }
    }
    }
      if (this.guestCount > this.onBoardingService?.outletDashboardDetails?.maxCoversAllowedGuest) {
      if (this.onetimeOutwarning) {
        document.getElementById('outletcateWarning').classList.add('show');
      }
    }
    
    // else if (outletType == RESERVATION_DETAILS_MODAL.POOL || RESERVATION_DETAILS_MODAL.SPA) {
    //     if (member == RESERVATION_DETAILS_MODAL.CHILD) {
    //         this.childCount += parseInt(COUNT.COUNT_1);
    //     }
    //     else if (member == RESERVATION_DETAILS_MODAL.ADULT) {
    //         this.adultCount += parseInt(COUNT.COUNT_1);
    //     }
    // }
  }


  selectCelebration(celebration: string) {
    this.celebration = celebration
  }

  applyPromocode() {
    if (!this.walkinForm.value.promocode) {
      return
    }
    let promocodeQueryStrig: ReservationPromocodeQueryString = {
      promoCode: this.walkinForm.value.promocode,
      tx: 'Reservation'
    };
    this.commonService.presentLoader('openReservation.applyPromocode');
    this.openReservationService.applyPromocode(promocodeQueryStrig).then((res) => {
      this.commonService.dismissLoader();
      if (res) {
        this.verifiedPromo = true
        this.promocodeDetails = res
        this.walkinForm.patchValue({
          "promocodeDetails": res
        })
      }
    });
  }

  callmemberMobileApi() {
    if (this.walkinForm.value.countryCodeSelected.dialCode && this.walkinForm.value.mobile) {
      var mobile = this.walkinForm.value.countryCodeSelected.dialCode + this.walkinForm.value.mobile;
    }
    if (this.walkinForm.value.email) {
      var email = this.walkinForm.value.email;
    }

    this.commonService.presentLoader('openReservation.verifyMobile');
    if(this.sendType =='email') {
        var valueSend = email
    } else {
      var valueSend = mobile
    }
    this.openReservationService.verifyMobileAndEmail(valueSend, this.sendType).then(
      (res) => {
        this.memeberApiRes = res
        this.memberProfileData = ""
        this.benefitDetails = ""
        this.commonService.dismissLoader();
        if (res) {
          this.userProfileData = res
          if (res.countrycode) {
            var aftersplit = res.countrycode.split('+')
            var resultMob = res.mobile.slice(aftersplit[1].length, res.mobile.length);
          }
          if(this.sendType == 'mobile') {
            this.callingAPIProfile = true
            this.checkByMobile = true
          }
          if(this.sendType == 'email') {
            this.callingAPIProfileByEmail = false
            this.checkByMobile = true
            
          }
          this.enableUserDetailsValue = true
          if (this.sendType == 'email') {
            this.enableMobileFeild = true
            this.walkinForm.patchValue({
              'genderCode': res.Salutation,
              'firstName': res.FirstName,
              'lastName': res.LastName,
            });
            this.walkinForm.get('mobile').setValue(resultMob)
            this.isValidNumber = true
          }
          else {
            this.enableEmailInput = true         
            this.walkinForm.patchValue({
              'genderCode': res.Salutation,
              'firstName': res.FirstName,
              'lastName': res.LastName,
              'email': res.email,
            });
            
          }
          
          this.SelectCode = aftersplit[1]
       
          let customerSetIdentifier = [];
          res.aemIdentifier.forEach(element => {
            element.customerSets.forEach(ele => {
              if (!ele.customerSetIdentifier.includes(environment.Content)) {
                ele.customerSetIdentifier = environment.Content + JAVASCRIPT.FORWARDSLASH + environment.CountryCode + JAVASCRIPT.FORWARDSLASH + this.localizationService.getDeviceLanguage() + ele.customerSetIdentifier;
              }
              customerSetIdentifier.push(ele.customerSetIdentifier)
            })
          });
          if (res.aemIdentifier.length) {
            this.getMembershipCardImages(res, customerSetIdentifier);

          } else {

          }
        }

      }
    ).catch((error) => {
      console.log("error")
      this.commonService.dismissLoader();
      if(this.sendType=='email') {
       this.callingAPIProfileByEmail = true
      } else {
      
      }
      this.memeberApiRes = ""
      this.callingAPIProfile = false
      this.enableUserDetailsValue = false
      this.enableMobileFeild = false
      this.enableEmailInput = false
      this.memberProfileData = ""
      this.benefitDetails = ""
      // this.commonService.errorHandler(error);
    })
  }

  getMembershipCardImages(response, customerSetIdentifier) {

    this.profileService.getSelectMembershipImageFunc({
      membershipType: customerSetIdentifier
    }).subscribe((aemResponse) => {
      if (aemResponse && aemResponse.ArrayList) {
        aemResponse = aemResponse.ArrayList;
        response.aemIdentifier.forEach(element => {
          element.customerSets.forEach(ele => {
            for (var i = 0; i < aemResponse.length; i++) {
              if (aemResponse[i].type === ele.customerSetIdentifier.substring(ele.customerSetIdentifier.lastIndexOf('/') + COUNT_NUMBER.COUNT_1, ele.customerSetIdentifier.length)) {
                ele[SPENDERS_PAGE.imageUrl] = aemResponse[i].cardImage;
              }
            }
          });
        });
        if (response.aemIdentifier.length === 1 && response.aemIdentifier[0].customerSets.length === 1) {
          this.getMemberProfileData(response.aemIdentifier[0].customerSets[0].membershipNumber);
        } else {
          this.openMembershipPopUp(response.aemIdentifier, response);
        }
      }
    })
  }

  async openMembershipPopUp(memberships, res) {
    const modal = await this.modalController.create({
      component: SelectMembershipComponent,
      componentProps: { 'memberships': memberships, 'response': res },
      cssClass: 'selectMembershipModal'
    });
    modal.present();
    modal.onWillDismiss().then((event) => {
      if (event && event.data && event.role) {
        this.getMemberProfileData(event.data.membershipNumber);
      } else {
        // this.mobileVerified = false;
        // this.expandBlock = 'mobile';
      }
    })
  }

  getMemberProfileData(membershipNumber: any) {
    this.certificateBenefits = [];
    this.cardBenefits = [];
    this.otherCertificateArr = [];
    const appData = this.app.getAppData();
    this.profileService.membershipNumber = membershipNumber;
    this.membershipNumber = membershipNumber;
    this.commonService.presentLoader('openReservation.membershipDetail');
    this.profileService.getmemberProfile().subscribe((res) => {
      this.commonService.dismissLoader();
      if (res) {
        this.memberProfileData = res;
        this.getBenefitsDetailFromAem(res.availableCertificates, 'certificate');
        this.getBenefitsDetailFromAem(res.cardBenefits, 'cardbenefit');

      }
    })
  }

  getBenefitsDetailFromAem(benefits: Array<any>, benefitsType: "certificate" | "cardbenefit") {
    let payload: IdentifierExporterPayload = {
      keys: []
    }
    switch (benefitsType) {
      case 'certificate':
        this.certificateBenefits = [];
        benefits.forEach((benefit) => {
          payload.keys.push({ identifier: benefit.benefitAemIdentifier, identifierType: benefitsType })
        });
        break;

      case 'cardbenefit':
        this.cardBenefits = [];
        benefits.forEach((benefit) => {
          payload.keys.push({ identifier: benefit.benefitAemIdentifier, identifierType: benefitsType })
        });
        break;
    }
    this.onBoardingService.aemDataApi(payload).subscribe(
      (res) => {
        if (res && res.data && res.data.items) {
          res = res.data.items;
          this.filterBenefitsFromAEM(res, benefits, benefitsType);
        }
      }
    );
  }

  filterBenefitsFromAEM(res, benefits: Array<any>, benefitsType: "certificate" | "cardbenefit") {
    switch (benefitsType) {
      case 'certificate':
        let certificateBenefitssss = []
        benefits.forEach((benefit) => {
          let aemBenefit = res.filter((data) => { return data.identifier.includes(benefit.benefitAemIdentifier) })
          if (aemBenefit.length > 0) {
            this.certificateBenefits.push({ sfdcData: benefit, aemData: aemBenefit[0] })
            certificateBenefitssss.push({ ...benefit, ...aemBenefit[0] })

          }
        })
        var ids = _.groupBy(certificateBenefitssss, 'identifier')
        var newArr = Array(ids);
        for (let [key, value] of (<any>Object).entries(newArr[0])) {
          this.otherCertificateArr.push({
            'Key': key,
            'certificateImage': value[0].certificateImage,
            'certificateStatus': value[0].certificateStatus,
            'Value': value,
            'certNum': value.length,

          })
        }
        break;

      case 'cardbenefit':
        benefits.forEach((benefit) => {
          let aemBenefit = res.filter((data) => { return data.identifier.includes(benefit.benefitAemIdentifier) })
          if (aemBenefit.length > 0) {
            this.cardBenefits.push({ sfdcData: benefit, aemData: aemBenefit[0] })
          }
        })
        break;
    }
    if (this.certificateBenefits.length || this.cardBenefits.length) {
      this.walkinForm.patchValue({
        benefitType: 'memberBenefits'
      });
      this.walkinForm.patchValue({
        benefit: this.certificateBenefits.length ? 'Certificate' : 'Card'
      })
    } else {
      this.walkinForm.patchValue({
        benefitType: 'promocode'
      });
    }
  }

  ChangePromo() {
    this.walkinForm.patchValue({
      "promocodeDetails": ""
    })
  }

  doWalkin() {
    this.commonService.presentLoader();
    var getDate = new Date(new Date())
    var sendDate = new Date(new Date()).toISOString().split(JAVASCRIPT.T)[COUNT.COUNT_0];
    var hour = getDate.getHours() > 9 ? getDate.getHours() : '0' + getDate.getHours();
    var minute = getDate.getMinutes() > 9 ? getDate.getMinutes() : '0' + getDate.getMinutes();
    var finalTime = hour + ':' + minute
    console.log('form value :----', this.walkinForm.value)

    var identify
    if ((this.walkinForm.value.mobile && this.walkinForm.value.email) || (this.walkinForm.value.mobile && this.walkinForm.value.email && this.walkinForm.value.walkinRoom)) {
      var dataNewApi
      identify = 'oldApi'
      dataNewApi = {
        // "reservationDate": sendDate,
        "reservationDate": moment().format('YYYY-MM-DD'),
        "reservationTime": finalTime + ':00',
        "outletsfid": this.ionicStorage.userDetails.outletId,
        "tcAccepted": true,
        "memberShipNumber": this.memberProfileData?.membershipDetails?.membershipNumber || "",
        "offerUniqueIdentifier": this.benefitDetails || '',
        "checkNumber": this.walkinForm.value.chequeNumber,
        "tableNumber": typeof (this.walkinForm.value.tableNumber) == 'object' ? String(this.walkinForm.value.tableNumber.tableNum) : String(this.walkinForm.value.tableNumber),
        "tableSfid": this.walkinForm.value.tableNumber.tableSfid ? this.walkinForm.value.tableNumber.tableSfid : '',
        "checkTable": true,
        "guestNames": "",
        "guestCount": this.guestCount.toString(),
        "hotelcomment": this.walkinForm.value.hotelComment,
        "promocode": this.verifiedPromo == true ? this.walkinForm.value.promocode : '',
        "celebration": this.celebration || "",
        "firstName": this.walkinForm.value.firstName,
        "lastName": this.walkinForm.value.lastName,
        "mobileNo": this.walkinForm.value.mobile ? this.SelectCode + this.walkinForm.value.mobile : '',
        "emailId": this.walkinForm.value.email,
        "memberId": this.memeberApiRes.memberId || "",
        "honorofic": this.walkinForm.value.genderCode,
        "roomNumber": this.walkinForm.value.walkinRoom
      }


    } else {
      var dataNewApi
      identify = 'newApi'
      dataNewApi = {
        "reservationDetails": {
          // "reservationDate": sendDate,
          "reservationDate": moment().format('YYYY-MM-DD'),
          "reservationTime": finalTime + ':00',
        },
        "memberDetails": {
          "firstName": this.walkinForm.value.firstName,
          "lastName": this.walkinForm.value.lastName,
          "mobileNo": this.walkinForm.value.mobile ? this.SelectCode + this.walkinForm.value.mobile : '',
          "emailId": this.walkinForm.value.email
        },
        "memberShipNumber": this.memberProfileData?.membershipDetails?.membershipNumber || "",
        "offerUniqueIdentifier": this.benefitDetails || '',
        "checkNumber": this.walkinForm.value.chequeNumber,
        "tableNumber": typeof (this.walkinForm.value.tableNumber) == 'object' ? String(this.walkinForm.value.tableNumber.tableNum) : String(this.walkinForm.value.tableNumber),
        "tableSfid": this.walkinForm.value.tableNumber.tableSfid ? this.walkinForm.value.tableNumber.tableSfid : '',
        "honorofic": this.walkinForm.value.genderCode,
        "roomNumber": this.walkinForm.value.walkinRoom,
        "memberId": this.memeberApiRes?.memberId || "",
        "celebration": {
          "occasion": this.celebration || "",
          "description": ""
        },
        "promocode": this.walkinForm.value.promocode,
        "hotelcomment": this.walkinForm.value.hotelComment,
        "guestNames": "",
        "guestCount": this.guestCount.toString(),
        "isTnCChecked": "true",
        "outletsfid": this.ionicStorage.userDetails.outletId,
      }
    }

    this.openReservationService.walkinApi(dataNewApi, identify).subscribe(
      (res) => {
        this.commonService.dismissLoader();
        if (res) {

          if (res.confirmationNumber) {
            var getTypemsg: string
            if (this.walkinForm.value.mobile == '' && this.walkinForm.value.email == '') {
              getTypemsg = "openReservation.confirmationNumberWalk"
            }
            if (this.walkinForm.value.mobile) {
              getTypemsg = "openReservation.confirmationNumberMobileWalk"
            }
            if (this.walkinForm.value.mobile && this.walkinForm.value.email) {
              getTypemsg = "openReservation.confirmationNumber"
            }
            if (this.walkinForm.value.email) {
              getTypemsg = "openReservation.confirmationNumber"
            }
            this.translate.get(
              'openReservation.reservationConfirmedPara',
              { outletName: res.outletDetails.outletName }
            ).subscribe((reservationConfirmationPara: string) => {
              this.translate.get(getTypemsg, { confirmationNumber: res.confirmationNumber }).subscribe(
                (confirmation) => {
                  this.confirmationNumberPara = confirmation;
                });
              this.reservationConfirmationPara = reservationConfirmationPara;
              document.getElementById("confirmReservationWalk").classList.add(JAVASCRIPT.SHOW);
            });
          } else {
            this.walkinapiRes = res
            document.getElementById("confirmwalkin").classList.add(JAVASCRIPT.SHOW);
          }


        }
      }, err => {
        console.log("err==>walk in", err)
      })
  }

  proceed() {
    document.getElementById("confirmwalkin").classList.remove(JAVASCRIPT.SHOW);
    document.getElementById("confirmReservationWalk").classList.remove(JAVASCRIPT.SHOW);
    this.event.publish('webDashboardHit', JAVASCRIPT.BLANK);
    window.location.reload()
    
  }



  selectBenefit(benefit, type) {
    this.walkinForm.patchValue({
      "promocodeDetails": "",
      "promocode":""
    })
    if (type == 'certificate') {
      this.guestCount = 1
      this.certificateType = 'Certificate'
      this.showBenefitName = benefit.Value[0].BenefitName
      this.enableBenefitName = true
      this.benefitDetails = benefit.Value[0].certificateBenefitId
      this.cardOutletApi()
    }
    else {
      this.guestCount = 1
      this.certificateType = 'Card'
      this.enableBenefitName = true
      this.showBenefitName = benefit.sfdcData.BenefitName
      this.benefitDetails = benefit.sfdcData.cardBenefitId
      this.cardOutletApi()
    }

  }

  removeBenifit() {
    this.showOneTimeBenefitWarning = true;
    this.enableBenefitName = false
    this.showBenefitName = ""
    this.benefitDetails = ""
    this.guestCount = 1
    this.benefitData = ''
  }

  expandBenefits() {
    this.benefitExpanded = !this.benefitExpanded
    $(`#avilableBenefitCard`).slideUp(500);
  }

  numberOnly(event: any) {
    let theEvent = event || window.event,
      key = theEvent.target.value,
      regex = /[0-9]+/g;
    // not remove this console 
    console.log("regex test", regex.test(key));
    if (!regex.test(key)) {
      let resp = event.target.value.match(regex)
      event.target.value = resp ? resp.join('') : ''
    }
  }
  walkinRoomFun() {
    if (this.walkinForm.value.walkinRoom != '') {
      this.walkinRoomValue = true
    }
    this.commonMethod()
  }



  commonMethod() {
    if (this.walkinForm.value.walkinRoom != '' && this.walkinForm.value.mobile != '' && this.walkinForm.value.email != '') {

      if (this.isValidNumber == true && this.emailChek == true) {
      
        this.enableButton = true
      } else {
        this.enableButton = false
      }
    } else if (this.walkinForm.value.walkinRoom != '' && this.walkinForm.value.mobile != '') {
      if (this.isValidNumber == true) {
        this.enableButton = true
      } else {
        this.enableButton = false
      }

    } else if (this.walkinForm.value.walkinRoom != '' && this.walkinForm.value.email != '') {
      if (this.emailChek == true) {
        this.enableButton = true
      } else {
        this.enableButton = false
      }
    } else if (this.walkinForm.value.email != '' && this.walkinForm.value.mobile != '') {
      if (this.isValidNumber == true && this.emailChek == true) {
        this.enableButton = true
      } else {
        this.enableButton = false
      }
    }
    else if (this.walkinForm.value.email != '') {
      if (this.emailChek == true) {
        this.enableButton = true
      } else {
        this.enableButton = false
      }
    }
    else if (this.walkinForm.value.mobile != '') {
      if (this.isValidNumber == true) {
        this.enableButton = true
      } else {
        this.enableButton = false
      }
    }
    else if (this.walkinForm.value.walkinRoom != '') {
      this.enableButton = true
    } else {
      this.enableButton = false
    }
  }

  allTableList() {
    this.dashboardService.tableAllotmentTypeApi(this.ionicStorage.userDetails.sfid, this.ionicStorage.userDetails.outletId, 'All').subscribe(res => {
      this.allVaccanTable = []
      if (res) {
        res.data.forEach(element => {
          if (element.available == true) {
            this.allVaccanTable.push(element)

          }
        });

      }
    })
  }
  cardOutletApi() {
    this.commonService.presentLoader()
    this.profileService.outletDeailsWalkIn(this.benefitDetails,this.certificateType).subscribe(res => {
      if (res) {
        this.commonService.dismissLoader()
          this.benefitData = res
      }
      else {

      }
    }, err => {
      this.commonService.dismissLoader()
      this.commonService.errorHandler(err);
      ;
    })
  }

  continueOutletWarning() {
    this.onetimeOutwarning = false
    document.getElementById('outletcateWarning').classList.remove('show');
  }
  continueCertificatee() {
    this.showOneTimeBenefitWarning = false
    document.getElementById('certifcateWarning').classList.remove('show');
  }





}
