import { LocalizationService } from '../localize/localization.service';
import { LocationService } from '../location/location.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { InitService } from '../../init/init.service';
import { LoadingController, Platform } from '@ionic/angular';
import { HTTPErrorHandler } from './http-error-handler';
import { AppStateService } from '../../init/app-state.service';
import { LocationCheckService } from '../../locationCheck.service';
import { OpenReservationService } from '../../open-reservation/open-reservation.service';
import { CommonService } from '../../common.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private initService: InitService,
    private loader: LoadingController,
    private localizationService: LocalizationService,
    private locationService: LocationService,
    private errorHandler: HTTPErrorHandler,
    private appState: AppStateService,
    public platform: Platform,
    private locationChack: LocationCheckService,
    private openReservationService: OpenReservationService,
    public common: CommonService,
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

   
    if (request.url.indexOf("assets/i18n") == -1 && request.url.indexOf("json/?key") == -1) {
      const headers = this.common.headerSendHttp()
      const appData = this.appState.getAppData();
      if (this.common.outletIDD) {
        headers['OutletID'] = this.common.outletIDD;
      } else {
        if (appData && appData.userDetails) {
          if (appData.userDetails.outletId) {
            headers['OutletID'] = appData.userDetails.outletId;
          }
        }
      }
      if (request.url.indexOf('activation') != -1) {
        headers['Ipaddress'] = localStorage.getItem('ip') || "";
      }

      if (appData && appData.employeeidHeader) {
        if (appData.employeeidHeader) {
          headers['employeeId'] = appData.employeeidHeader;
        }

      }


      if (appData && appData.sessionToken) {
        if (appData.sessionToken) {
          headers['SessionIDToken'] = appData.sessionToken;
        }
      }

      if (appData && appData.csrftoken) {
        if (appData.csrftoken) {
          headers['csrftoken'] = this.common?.csrfToken ||  appData.csrftoken;
          
        }
      }

      //  Last modify 17-aug-21 ... THA-572 Anonymous API change to post
      // if (request.url.indexOf("anonymouskey") != -1 && this.platform.is('cordova')) {
      //   if (appData.devicCheckBase64) {
      //     headers['device_token'] = appData.devicCheckBase64.saveDevicecheckBase64;

      //   }
      //   if (this.platform.is('ios')) {
      //     headers['device_type'] = 'IOS';
      //   } else {
      //     headers['device_type'] = 'ANDROID';
      //   }
      // }


      let reqHeaders = { 'Content-Type': 'application/json' };
      if (request.url.indexOf('identifiersExporter') === -1  && request.url.indexOf('walletcardexporter') === -1) {
        if (appData.userPageAccess != undefined && request.url.indexOf("logout") == -1) {
          this.locationChack.getUserLocation(appData.userPageAccess);
        }
        reqHeaders = headers;
      }
   

      const updatedRequest = request.clone({
        headers: new HttpHeaders(reqHeaders)

      });

      return next.handle(updatedRequest).do((event: HttpEvent<any>) => { }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          console.log("MAIN ERROR ==>>",err)
          this.errorHandler.handleError(err);
        }
      });
    } else {
      return next.handle(request);
    }
  }
}

