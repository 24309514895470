import { Injectable, NgZone } from '@angular/core';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { CommonService } from './common.service';
// import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { AppStateService } from './init/app-state.service';
import { Router, NavigationExtras } from '@angular/router';
import { DashboardhomeService } from './dashboardhome.service';
import { QrScannerComponent } from '../modals/qr-scanner/qr-scanner.component';
import { JAVASCRIPT, COUNT_NUMBER, LOGIN_PAGE } from '../app.constants';
import { ProfileService } from './profile.service';
import { BarcodeScanner, BarcodeScannerOptions } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { AlertController } from '@ionic/angular';
import { StorageService } from './core/storage/storage.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';






@Injectable({
  providedIn: 'root'
})
export class RedeemQRService {
  ionicStorageData: any;
  counter: number = 0;
  brandName: any
  constructor(public common: CommonService,
    private barcodeScanner: BarcodeScanner,
    private menu: MenuController,
    private router: Router,
    public dashboardService: DashboardhomeService,
    private modalCtrl: ModalController,
    private profileService: ProfileService,
    public appState: AppStateService,
    private openNativeSettings: OpenNativeSettings,
    public alertCtrl: AlertController,
    public storageService: StorageService,
    public platform: Platform,
    private translate: TranslateService,
    public zone: NgZone,
  ) 
  {}

  async barCodeScanner() {
    if(localStorage.getItem(LOGIN_PAGE.loggedUser)){
      this.translate.get(environment.brandName).subscribe((res: string) => {
           this.zone.run(() => {
           this.brandName = res
        });
      })
    }
    console.log("barCodeScanner from redeemQR service");
    const options: BarcodeScannerOptions = {
      prompt: JAVASCRIPT.BLANKDOUBLE,
      resultDisplayDuration: COUNT_NUMBER.COUNT_0,
    };
    this.ionicStorageData = this.appState.getAppData();
    this.barcodeScanner.scan(options)
      .finally(() => {
        this.menu.close()
      }).then(barcodeData => {
        var scenData = barcodeData.text.split('|');
        console.log("barcodeData", barcodeData)
        console.log("scenData", scenData)
        if (scenData[scenData.length - 1] == 'redemption') {
          let currTimeStamp = new Date().getTime();
          let checkScenTime = (currTimeStamp - parseInt(scenData[COUNT_NUMBER.COUNT_1])) / 60000;

          if (barcodeData.cancelled) {
            //console.log("barcodeData.cancelled")
            return this.router.navigateByUrl('web-dashboard');
          } else if (scenData[0] != this.ionicStorageData.appInitData.qr_scanner.brandName || scenData.length > COUNT_NUMBER.COUNT_7) {
            this.common.errorRedeemQRHandlerAction();
            //console.log("2nd condition ")
            return this.router.navigateByUrl('web-dashboard');
          } else if (this.ionicStorageData.appInitData.qr_scanner.Timer <= checkScenTime && scenData[COUNT_NUMBER.COUNT_4] == JAVASCRIPT.YES) {
            this.common.errorRedeemQRHandler();
            //console.log("3RD condition ")
            return this.router.navigateByUrl('web-dashboard');
          } else {
            this.barCodeScannerAPICall(barcodeData);
          }
        } else if (scenData[scenData.length - 1] == 'memberDetails') {
          if (barcodeData.cancelled) {
            //console.log("barcodeData.cancelled memberDetails")
            return this.router.navigateByUrl('web-dashboard');
          } else if (scenData[0] != this.ionicStorageData.appInitData.qr_scanner.brandName) {
            this.common.errorRedeemQRHandlerAction();
            //console.log("2nd memberDetails condition ")
            return this.router.navigateByUrl('web-dashboard')
          } else {
            //console.log("barcodeDat succes memberDetails", scenData[1])
            let navigationExtras: NavigationExtras = {
              state: { membershipNumber: scenData[1] }
            }
            this.profileService.membershipNumber = scenData[1];
            this.router.navigate(['guest-profile'], navigationExtras);
          }

        } else if (scenData[scenData.length - 1] == 'manage_reservation') {
          //console.log("manage_reservation Inside")
          if (scenData[0] != this.ionicStorageData.appInitData.qr_scanner.brandName) {
            this.common.errorRedeemQRHandlerAction();
            //console.log("2nd manage_reservation condition ")
            return this.router.navigateByUrl('web-dashboard')

          } else if (scenData[3] != this.ionicStorageData.userDetails.outletId) {
            //console.log("invalid outlet")
            this.common.errorInvlidOutletQRHandlerAction();

          } else if (scenData[4] == 'Cancelled' || scenData[4] == 'Redeem') {
            //console.log("status cancelled")
            if (scenData[4] == 'Cancelled') {
              this.common.errorCancelledQRHandlerAction();
            } else {
              this.common.erroralreadyRedeemQRHandlerAction();
            }

          }
          else {
            //console.log("barcodeDat succes manage_reservation", scenData[2])
            const queryParams = { id: scenData[2] }
            this.router.navigate(['manage-reservation'], {
              queryParams: queryParams
            });
          }
        }

        else {
          //console.log("testing")
          if (barcodeData.cancelled) {
            //console.log("barcodeData.cancelled last else ")
            return this.router.navigateByUrl('web-dashboard')
          } else {
            this.common.errorRedeemQRHandlerAction();
          }
        }
      }

      ).catch(err => {

        var counterval = parseInt(localStorage.getItem('counter'));
        if (this.platform.is('ios') && counterval >= 0) {

          console.log('Error for ios', err, this.counter);
          this.showAlert();
        }
        else if (this.platform.is('android') && counterval >= 1) {
          console.log('Error for android', err, this.counter);
          this.showAlert();
        }
        else {
          localStorage.setItem("counter", this.counter.toString());
          this.counter++;
        }



      });
  }

  async barCodeScannerAPICall(barcodeData : any) {
    console.log("barcodeData", barcodeData)
    let modal;
    modal = await this.modalCtrl.create({
      component: QrScannerComponent
    });
    modal.present();
    const barCodeStatus = await modal.onWillDismiss();
    if (barCodeStatus.data) {
      this.common.presentLoader();
      this.dashboardService.callbarCodeCheck(this.ionicStorageData, barcodeData.text)
        .subscribe(res => {
          console.log("resQR=>>", res)
          this.common.dismissLoader();
          if (res != undefined) {
            this.router.navigateByUrl('redeemed');
          } else {
            this.router.navigateByUrl('web-dashboard');
          }
        }, err => {
          this.common.dismissLoader();
          //console.log("err=>>", err)
        })
    }
  }

  showAlert() {

    this.alertCtrl.create({
      header: 'Camera permission required',
      subHeader: `Allow ${this.brandName} to take pictures and record video?`,
      buttons: [{
        text: 'Don’t Allow',
        id: 'cancel-button',
        role: 'cancel',
        handler: (blah) => {
          console.log('Confirm Cancel: blah');
        }
      },
      {
        text: 'Allow',
        id: 'confirm-button',
        handler: () => {
          this.openNativeSettings.open('application_details').then(res => {
            console.log("success", res);
          }).catch(err => {
            console.log("err", err);
          });
          console.log('Confirm Okay');
        }
      }]
    }).then(res => {

      res.present();

    });

  }

}