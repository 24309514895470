import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './auth/authguard.guard';
import { DeactivateGuard } from './auth/deactive-guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/welcome-page/welcome-page.module').then(m => m.WelcomePagePageModule), 
    canActivate:[DeactivateGuard]
  },
  {
    path: 'web-dashboard',
    loadChildren: () => import('./pages/web-dashboard/web-dashboard.module').then( m => m.WebDashboardPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate:[AuthguardGuard],
    
  },

  {
    path: 'setting',
    loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'faqs',
    loadChildren: () => import('./pages/faqs/faqs.module').then(m => m.FaqsPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'redemption-log',
    loadChildren: () => import('./pages/redemption-log/redemption-log.module').then(m => m.RedemptionLogPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'view-reservations',
    loadChildren: () => import('./pages/dashboard/view-reservations/view-reservations.module').then(m => m.ViewReservationsPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'reservation-master',
    loadChildren: () => import('./pages/dashboard/reservation-master/reservation-master.module').then(m => m.ReservationMasterPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'member-search',
    loadChildren: () => import('./pages/dashboard/member-search/member-search.module').then(m => m.MemberSearchPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'record-exception',
    loadChildren: () => import('./pages/dashboard/record-exception/record-exception.module').then(m => m.RecordExceptionPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'open-reservation',
    loadChildren: () => import('./pages/open-reservation/open-reservation.module').then(m => m.OpenReservationPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'outlet-master',
    loadChildren: () => import('./pages/outlet-master/outlet-master.module').then(m => m.OutletMasterPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'select-outlet',
    loadChildren: () => import('./pages/select-outlet/select-outlet.module').then(m => m.SelectOutletPageModule),
    canActivate:[DeactivateGuard]
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule),
    canActivate:[DeactivateGuard]
  },
  {
    path: 'welcome-page',
    loadChildren: () => import('./pages/welcome-page/welcome-page.module').then(m => m.WelcomePagePageModule),
    canActivate:[DeactivateGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate:[DeactivateGuard]
  },
  {
    path: 'activate-account',
    loadChildren: () => import('./pages/activate-account/activate-account.module').then(m => m.ActivateAccountPageModule),
    canActivate:[DeactivateGuard]
  },
  {
    path: 'setup-profile',
    loadChildren: () => import('./pages/setup-profile/setup-profile.module').then(m => m.SetupProfilePageModule),
    canActivate:[DeactivateGuard]
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'reservations-awaiting-confirmation',
    loadChildren: () => import('./pages/dashboard/reservations-awaiting-confirmation/reservations-awaiting-confirmation.module').then(m => m.ReservationsAwaitingConfirmationPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'manage-reservation',
    loadChildren: () => import('./pages/dashboard/manage-reservation/manage-reservation.module').then(m => m.ManageReservationPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'certificate-detail',
    loadChildren: () => import('./pages/dashboard/certificate-detail/certificate-detail.module').then(m => m.CertificateDetailPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'guest-profile',
    loadChildren: () => import('./pages/dashboard/guest-profile/guest-profile.module').then(m => m.GuestProfilePageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'available-certificates',
    loadChildren: () => import('./pages/dashboard/available-certificates/available-certificates.module').then(m => m.AvailableCertificatesPageModule),
    canActivate:[AuthguardGuard],
  },

  {
    path: 'card-benefits',
    loadChildren: () => import('./pages/dashboard/card-benefits/card-benefits.module').then(m => m.CardBenefitsPageModule),
    canActivate:[AuthguardGuard],
  },
 
  {
    path: 'preferences',
    loadChildren: () => import('./pages/dashboard/preferences/preferences.module').then(m => m.PreferencesPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'handle-with-care',
    loadChildren: () => import('./pages/dashboard/handle-with-care/handle-with-care.module').then(m => m.HandleWithCarePageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'member-review',
    loadChildren: () => import('./pages/dashboard/member-review/member-review.module').then(m => m.MemberReviewPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'redeem-benefits',
    loadChildren: () => import('./pages/dashboard/redeem-benefits/redeem-benefits.module').then(m => m.RedeemBenefitsPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'reason-exception',
    loadChildren: () => import('./pages/dashboard/record-exception/reason-exception/reason-exception.module').then(m => m.ReasonExceptionPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'awaiting-arrival',
    loadChildren: () => import('./pages/dashboard/awaiting-arrival/awaiting-arrival.module').then(m => m.AwaitingArrivalPageModule),
    canActivate:[AuthguardGuard],
  },
  
  {
    path: 'close-cheque',
    loadChildren: () => import('./pages/dashboard/close-cheque/close-cheque.module').then(m => m.CloseChequePageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'in-progress',
    loadChildren: () => import('./pages/dashboard/in-progress/in-progress.module').then(m => m.InProgressPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'view-reservations',
    loadChildren: () => import('./pages/dashboard/view-reservations/view-reservations.module').then(m => m.ViewReservationsPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'reservation-master',
    loadChildren: () => import('./pages/dashboard/reservation-master/reservation-master.module').then(m => m.ReservationMasterPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'redeemed',
    loadChildren: () => import('./pages/dashboard/redeem-benefits/redeemed/redeemed.module').then(m => m.RedeemedPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'redeem-otp',
    loadChildren: () => import('./pages/dashboard/redeem-benefits/redeem-otp/redeem-otp.module').then(m => m.RedeemOtpPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'redeem-qr',
    loadChildren: () => import('./pages/dashboard/redeem-benefits/redeem-qr/redeem-qr.module').then(m => m.RedeemQrPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'add-club',
    loadChildren: () => import('./pages/dashboard/add-club/add-club.module').then(m => m.AddClubPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/dashboard/notifications/notifications.module').then(m => m.NotificationsPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'user-dashboard',
    loadChildren: () => import('./pages/dashboard/user-dashboard/user-dashboard.module').then(m => m.UserDashboardPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'spenders',
    loadChildren: () => import('./pages/dashboard/user-dashboard/spenders/spenders.module').then(m => m.SpendersPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'pos-audit',
    loadChildren: () => import('./pages/dashboard/user-dashboard/pos-audit/pos-audit.module').then(m => m.PosAuditPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'offer-cheque',
    loadChildren: () => import('./pages/dashboard/offer-cheque/offer-cheque.module').then(m => m.OfferChequePageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'offer-details',
    loadChildren: () => import('./pages/dashboard/offer-details/offer-details.module').then(m => m.OfferDetailsPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'redemption-listing',
    loadChildren: () => import('./pages/redemption-listing/redemption-listing.module').then(m => m.RedemptionListingPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'outlet-details',
    loadChildren: () => import('./pages/outlet-details/outlet-details.module').then(m => m.OutletDetailsPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'activate-reservation',
    loadChildren: () => import('./pages/activate-reservation/activate-reservation.module').then(m => m.ActivateReservationPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'reservation-list',
    loadChildren: () => import('./pages/reservation-list/reservation-list.module').then(m => m.ReservationListPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'reservation-count',
    loadChildren: () => import('./pages/dashboard/reservation-master/reservation-count/reservation-count.module').then(m => m.ReservationCountPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'reservation-block',
    loadChildren: () => import('./pages/dashboard/reservation-master/reservation-block/reservation-block.module').then(m => m.ReservationBlockPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'reservation',
    loadChildren: () => import('./pages/reservation/reservation.module').then(m => m.ReservationPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'view-outlet-master',
    loadChildren: () => import('./pages/outlet-master/view/view.module').then(m => m.ViewPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/mbowdelivery/orders/orders.module').then(m => m.OrdersPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'order-details',
    loadChildren: () => import('./pages/mbowdelivery/order-details/order-details.module').then(m => m.OrderDetailsPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'user-details',
    loadChildren: () => import('./pages/mbowdelivery/user-details/user-details.module').then(m => m.UserDetailsPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'setup-outlet',
    loadChildren: () => import('./pages/mbowdelivery/setup-outlet/setup-outlet.module').then(m => m.SetupOutletPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'setup-rules',
    loadChildren: () => import('./pages/mbowdelivery/setup-rules/setup-rules.module').then(m => m.SetupRulesPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'setup-widget',
    loadChildren: () => import('./pages/mbowdelivery/setup-widget/setup-widget.module').then( m => m.SetupWidgetPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'setup-menu',
    loadChildren: () => import('./pages/mbowdelivery/setup-menu/setup-menu.module').then( m => m.SetupMenuPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/mbowdelivery/menu/menu.module').then( m => m.MenuPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'edit-menu',
    loadChildren: () => import('./pages/mbowdelivery/edit-menu/edit-menu.module').then( m => m.EditMenuPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'update-sequence',
    loadChildren: () => import('./pages/mbowdelivery/edit-menu/edit-menu.module').then( m => m.EditMenuPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'block-service',
    loadChildren: () => import('./pages/mbowdelivery/block-service/block-service.module').then( m => m.BlockServicePageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'revenue-management',
    loadChildren: () => import('./pages/mbowdelivery/revenue-management/revenue-management.module').then( m => m.RevenueManagementPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'item-not-available',
    loadChildren: () => import('./pages/mbowdelivery/item-not-available/item-not-available.module').then( m => m.ItemNotAvailablePageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'update-item',
    loadChildren: () => import('./pages/mbowdelivery/update-item/update-item.module').then( m => m.UpdateItemPageModule),
    canActivate:[AuthguardGuard],
  },

  {
    path: 'benefit-details',
    loadChildren: () => import('./pages/dashboard/benefit-details/benefit-details.module').then( m => m.BenefitDetailsPageModule),
    canActivate:[AuthguardGuard],
  },

  {
    path: 'table-master',
    loadChildren: () => import('./pages/table-master/table-master.module').then( m => m.TableMasterPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'outlet-section',
    loadChildren: () => import('./pages/outlet-section/outlet-section.module').then( m => m.OutletSectionPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'tags',
    loadChildren: () => import('./tags/tags.module').then( m => m.TagsPageModule),
    canActivate:[AuthguardGuard],
  },
  {
    path: 'table-list',
    loadChildren: () => import('./pages/table-list/table-list.module').then( m => m.TableListPageModule),
    canActivate:[AuthguardGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }