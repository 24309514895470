import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { HOURS, MINUTES, COUNT, TIME_24H, COUNT_NUMBER, JAVASCRIPT, RESERVATION_DETAILS_MODAL, CUSTOM_SELECT_ICON, SET_DATE_MODAL } from 'src/app/app.constants';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { TranslateService } from '@ngx-translate/core';
import { MbscDatepicker } from '@mobiscroll/angular';
declare var $: any

@Component({
    selector: 'app-reservation-details',
    templateUrl: 'reservation-details.component.html',
    styleUrls: ['./reservation-details.component.scss'],
})
export class ReservationDetailsComponent implements OnInit {
    guestCount: number = COUNT_NUMBER.COUNT_0
    childCount: number = COUNT_NUMBER.COUNT_0
    adultCount: number = COUNT_NUMBER.COUNT_0
    dateObj: any = {
        startDate: JAVASCRIPT.BLANK,
        minuteSelect: JAVASCRIPT.BLANK,
        hoursSelect: JAVASCRIPT.BLANK,
        comment: JAVASCRIPT.BLANK,
        therapyValue: JAVASCRIPT.BLANK,
        spaDurationValue: COUNT_NUMBER.COUNT_0
    }
    showDate: any;
    dateOptionChoosed: any = TIME_24H.AM
    timeHoursArry: any[];
    hours: any = [];
    minutes: any[];
    selectedHours: any;
    selectedMinute: any;
    finalTime: any;
    getDataReservaiton: any = [];
    modifiyStoreData: any;
    minDateDisabled: string = new Date().toISOString();
    maxDateEnable: any = (new Date()).getFullYear() + 20;
    showHandingonHours: any;
    hoursSelect: any;
    minuteSelect: any;
    monthShortNames: string;
    @ViewChild('picker', { static: false })
    pickerInst: MbscDatepicker;
    queryException: any;


    constructor(private modalController: ModalController, navParams: NavParams, public appState: AppStateService, private translate: TranslateService) {

        this.getDataReservaiton = navParams.get(RESERVATION_DETAILS_MODAL.reservation_details_send);
        this.timeStoreInModal()

    }
    timeStoreInModal() {
        let timeEdit = this.getDataReservaiton.reservationTime.split(TIME_24H.COUNT_ADD)
        let timeAMPM = timeEdit[1].split(JAVASCRIPT.SPACE)
        this.dateObj.hoursSelect = timeEdit[COUNT_NUMBER.COUNT_0];
        this.dateObj.minuteSelect = timeAMPM[COUNT_NUMBER.COUNT_0];
        this.dateOptionChoosed = timeAMPM[COUNT_NUMBER.COUNT_1];
    }


    ngOnInit() {
        console.log(" this.dateObj", this.dateObj)
        this.modifiyStoreData = this.appState.getAppData();
        // This value from constact file and show on modal hours and minutes
        this.hours = HOURS;
        this.minutes = MINUTES;
        // End....
        // this.saveDataonModalfuntion()
        // this.translate.get(RESERVATION_DETAILS_MODAL.reservationDetailsModal).subscribe(
        //     (res) => {
        //         this.showHandingonHours = res
        //     })
        // this.hoursSelect = {
        //     header: this.showHandingonHours.hour,
        //     translucent: true
        // }
        // this.minuteSelect = {
        //     header: this.showHandingonHours.minute,
        //     translucent: true
        // }

    }
    ionViewWillEnter() {
        this.translate.get(SET_DATE_MODAL.customMonth).subscribe((res: string) => {
            this.monthShortNames = res
        });
        this.saveDataonModalfuntion();
        this.translate.get(RESERVATION_DETAILS_MODAL.reservationDetailsModal).subscribe(
            (res) => {
                this.showHandingonHours = res
            })
        this.hoursSelect = {
            header: this.showHandingonHours.hour,
            translucent: true
        }
        this.minuteSelect = {
            header: this.showHandingonHours.minute,
            translucent: true
        }

    }

    saveDataonModalfuntion() {
        this.dateObj.startDate = this.getDataReservaiton.reservationDate
        this.guestCount = this.getDataReservaiton.guestCount
        if (this.getDataReservaiton.spaDuration != JAVASCRIPT.BLANK) {
            this.dateObj.spaDurationValue = this.getDataReservaiton.spaDuration
        }
        this.dateObj.therapyValue = this.getDataReservaiton.spaTreatment
        if (this.getDataReservaiton.kidsCount != JAVASCRIPT.BLANK) {
            this.childCount = this.getDataReservaiton.kidsCount
        }
        // this.childCount = this.getDataReservaiton.kidsCount
        this.adultCount = this.getDataReservaiton.adultCount
        this.dateObj.comment = this.getDataReservaiton.specialrequest

    }

    closeModal() {
        this.modalController.dismiss({
            'dismissed': true
        });

    }

    getDate(val) {
        if (val == TIME_24H.AM) {
            this.dateOptionChoosed = TIME_24H.AM
        }
        else if (val == TIME_24H.PM) {
            this.dateOptionChoosed = TIME_24H.PM
        }
    }

    gethour(time) {
        this.dateObj.hoursSelect = time.target.value

    }
    getMin(minutes) {
        ////console.log(minutes)

    }


    onClick(outletType, member) {
        if (outletType == RESERVATION_DETAILS_MODAL.RESTAURANT) {
            if (member == RESERVATION_DETAILS_MODAL.GUEST) {
                this.guestCount += parseInt(COUNT.COUNT_1);
            }
        }
        else if (outletType == RESERVATION_DETAILS_MODAL.POOL || RESERVATION_DETAILS_MODAL.SPA) {
            if (member == RESERVATION_DETAILS_MODAL.CHILD) {
                this.childCount += parseInt(COUNT.COUNT_1);
            }
            else if (member == RESERVATION_DETAILS_MODAL.ADULT) {
                this.adultCount += parseInt(COUNT.COUNT_1);
            }
        }
    }
    onDecrement(outletType, member) {
        if (outletType == RESERVATION_DETAILS_MODAL.RESTAURANT) {
            if (member == RESERVATION_DETAILS_MODAL.GUEST) {
                if (this.guestCount != parseInt(COUNT.COUNT_0)) {
                    this.guestCount -= parseInt(COUNT.COUNT_1);
                }
            }
        }
        else if (outletType == RESERVATION_DETAILS_MODAL.POOL) {
            if (member == RESERVATION_DETAILS_MODAL.CHILD) {
                if (this.childCount != parseInt(COUNT.COUNT_0)) {
                    this.childCount -= parseInt(COUNT.COUNT_1);
                }
            }
            else if (member == RESERVATION_DETAILS_MODAL.ADULT) {
                if (this.adultCount != parseInt(COUNT.COUNT_0)) {
                    this.adultCount -= parseInt(COUNT.COUNT_1);

                }
            }
        }
    }

    dateFun() {
        var date
        date = this.dateObj.startDate
        console.log("mange=>",date)
        this.showDate = date.split(JAVASCRIPT.T)[parseInt(COUNT.COUNT_0)]
    }

    modifyNavigation() {
        if (this.dateOptionChoosed == TIME_24H.PM) {
            this.finalTime = parseInt(this.dateObj.hoursSelect) + parseInt(COUNT.COUNT_12)
        }
        else if (this.dateOptionChoosed == TIME_24H.AM) {
            if(this.dateObj.hoursSelect =='12') {
                this.finalTime = '00'
            } else {
                this.finalTime = this.dateObj.hoursSelect
            }
        }
        var sendate = new Date(this.dateObj.startDate)
        this.dateObj.startDate = new Date(sendate.getTime() - (sendate.getTimezoneOffset() * 60000)).toISOString().split(JAVASCRIPT.T)[COUNT.COUNT_0];
        console.log("this.dateObj.startDate",this.dateObj.startDate)
        let data = {
           
            'date': this.showDate || this.dateObj.startDate,
            "hours": this.dateObj.hoursSelect,
            "minute": this.dateObj.minuteSelect,
            "guest": this.guestCount,
            "chilCount": this.childCount,
            "adultCount": this.adultCount,
            "time": this.finalTime,
            "specialRequest": this.dateObj.comment,
            "spaTreatment": this.dateObj.therapyValue,
            "spaDuration": parseFloat(this.dateObj.spaDurationValue),
            "ampmChoose":this.dateOptionChoosed
       
        }
        console.log("data", data)
        this.modalController.dismiss({
            'modifyModalData': data
        });
    }

    //Custom Select Icon
    ionViewDidEnter() {
        const ionSelects = document.querySelectorAll(CUSTOM_SELECT_ICON.ionSelect);
        ionSelects.forEach((sel) => {
            sel.shadowRoot.querySelectorAll(CUSTOM_SELECT_ICON.selectIcon)
                .forEach((elem) => {
                    elem.setAttribute(JAVASCRIPT.STYLE, JAVASCRIPT.DISPLAY_NONE);
                });
        });
    }

    spaSlectTretment(tretmentValue) {
        this.dateObj.therapyValue = tretmentValue.target.value
    }

  

    openCalendar() {
        // document.getElementById(RESERVATION_DETAILS_MODAL.reservationDetailsDateTime).click();
        this.pickerInst.open();
    }

    restrictText(e) {
        console.log("e value ==", e)
        if (this.dateObj.comment.length == COUNT_NUMBER.COUNT_99) {
          e.preventDefault();
        } else if (this.dateObj.comment.length > COUNT_NUMBER.COUNT_99) {
          // Maximum exceeded
          this.dateObj.comment = this.dateObj.comment.substring(COUNT_NUMBER.COUNT_0, COUNT_NUMBER.COUNT_99);
        }
      }
}
