import { Component, OnInit, Input } from '@angular/core';
import { InitService } from 'src/app/services/init/init.service';
import { Router } from '@angular/router';
import { AppStateService } from 'src/app/services/init/app-state.service';
import { AlertController, Platform } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { ChatService } from 'src/app/services/chat.service';
import { QUERY_PAGE } from 'src/app/app.constants';

@Component({
  selector: 'query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss'],
})
export class QueryComponent implements OnInit {

  @Input(QUERY_PAGE.queryKind) queryKind;
  @Input(QUERY_PAGE.show) show: string;
  @Input(QUERY_PAGE.chat) chat: any;
  storedData: any;
  appStateData: any;
  callNo = '+91 1143169678'
  constructor(
    public service: InitService,
    public router: Router,
    private platform: Platform,
    private appState: AppStateService,
    public alertController: AlertController,
    public common: CommonService,
    public chatService: ChatService
  ) { }

  ngOnInit() {
    this.storedData = this.service.storeResponse;
    this.appStateData = this.appState.getAppData()

  }
  //Function to navigate to chat page..v//
  navigateToChat() {
    if (this.platform.is('cordova')) {
      this.chatService.startWithVisitorChat();
    }
  }



}
