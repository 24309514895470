import { NavigationStart, Router, Event } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { EventService } from 'src/app/services/event/event.service';
import { BACK_BUTTON, LOGIN_PAGE } from 'src/app/app.constants';

@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})

export class BackButtonComponent implements OnInit {
  @Input() backPage: string = null;
  @Input(BACK_BUTTON.show) show: string;
  @Input(BACK_BUTTON.select) select: string;
  titleVal: any;
  currentUrl: string;
  constructor(
    public events: EventService,
    public navCtrl: NavController,
    public router: Router
  ) {
    this.events.subscribe(BACK_BUTTON.title, titleData => {
      this.backPage = titleData
    });
  }

  ngOnInit() { }

  navigationBack() {
    console.log("navigationBack1")
    this.navCtrl.setDirection('back');
    
    this.navCtrl.pop();
    console.log("navigationBack2")
    // this.navCtrl.back();
  }

}


