import { AppStateService } from './app-state.service';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpWrapperService, HttpInputData } from './../core/http/http-wrapper.service';
import { appApiResources, LOGIN_PAGE, storageKeys } from './../../app.constants';
import { Observable } from 'rxjs/Rx';
import { EncryptionService } from '../core/encryption/encryption.service';
import { CommonService } from '../common.service';
import { StorageService } from '../core/storage/storage.service';
import { EventService } from '../event/event.service';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
@Injectable({
  providedIn: 'root'
})
/**
 * Loads the App Init from network/storage to App state
 */
export class InitService {
  loadingDialog: any = null;
  todayDate = new Date().getTime();
  environment = null;
  uuid = null;
  userType;
  storeResponse: any;
  beforeEncryKey: { beforesecureKey: any, beforesecureIVKey: any };
  ionicStorageData: any
  enableTosaveLoginData: boolean;
  hidesplashScreen: boolean;
  deiviceStatus: any;
  apploginCheck: string;
  deviceToken: string;

  constructor(
    private httpWrapper: HttpWrapperService,
    private appState: AppStateService,
    private splashScreen: SplashScreen,
    public navCtrl: NavController,
    public encryptService: EncryptionService,
    public common: CommonService,
    public storageService: StorageService,
    public event: EventService,
    private platform: Platform,
    public storage: StorageService,
    public alertController: AlertController,
    public modalController: ModalController,) {
    this.environment = environment;
  }

  getInitApp(type): Observable<any> {
    var url;
    if (type == "firstTimeUser") {
      url = appApiResources.appInit;
      return this.httpWrapper
        .get(url)
        .map((response: any) => {
          return response;
        })
        .catch((error: Response) => this.handleError(error)
        )
        .finally(() => { });
    } else if (type == "checkUpdatedData") {
      url = appApiResources.appInit;
      return this.httpWrapper
        .get(url)
        .map((response: any) => {
          if (this.deiviceStatus == true) {
            // this.checkBuildVersion()
          }
          return response;
        })
        .catch((error: Response) => this.handleError(error))
        .finally(() => { });
    } else {
    }
  }

  async encryptiongetInitApp() {
    const appData = this.appState.getAppData();
    console.log("appData",appData)
    
    if(appData.devicCheckBase64) {
      this.deviceToken = appData.devicCheckBase64.saveDevicecheckBase64
    }

    let options = new HttpInputData();
    if (this.platform.is("cordova")) { 
      var datapass = {
        "device_token":this.deviceToken
      }
      
    
    } else {
      var datapass = {
        "device_token":"statictoken1222222342342322"
      }
    }
   
    return new Promise((resolve, reject) => {
      this.httpWrapper.postHeader('program/anonymouskey',datapass,options,true).subscribe(async (res: any) => {
        console.log("ress anomus=>", res)
        console.log("header res=>" ,res.headers.get("csrftoken"))
        if (res) {
          // if (this.platform.is("cordova")) {
          //   if (res['body'].devicecheck == false) {
          //     this.deviceCheckAlert();
          //     return;
          //   }
          // }
          this.common.csrfToken = res.headers.get("csrftoken")
          localStorage.setItem(storageKeys.anonymousKey,JSON.stringify(res.body))
          this.encryptService.beforesecureKeyValue = await res['body'].secureKey
          this.encryptService.beforesecureIVKeyValue = await res['body'].secureIV;
          this.deiviceStatus = res['body'].devicecheck
          this.appState.saveCsrfToken(res.headers.get("csrftoken"));
          resolve(res)
          this.saveCsrfToken();
        }
      },
        (error: any) => {
          this.splashScreen.hide();
          this.common.dismissLoaderInit()
          //console.log("errorerror", error)
          resolve(error)
        }
      )
    });
  }

  async deviceCheckAlert() {
    const errorAlert = await this.alertController.create({
      mode: "ios",
      backdropDismiss: false,
      message: "Your device is not authorized for this app.",
      cssClass: "alertCustomCss",
    });
    await errorAlert.present();
  }

  // Save CSRF Token  
  saveCsrfToken() {
    this.ionicStorageData = this.appState.getAppData();
  }

  authenticatekeyApi() {
    let options = new HttpInputData();
    return new Promise((resolve, reject) => {
      this.httpWrapper.get(`program/authenticatekey`).subscribe(
        async (res) => {
          this.common.dashboardApi = true
          //console.log("what authticateApi ress==>>" + JSON.stringify(res))
          localStorage.setItem(storageKeys.authenticatekey,JSON.stringify(res))
          this.encryptService.afterescureKeyValue = res.secureKey;
          this.encryptService.afterescureIVKeyValue = res.secureIV;
          await this.appState.loadAppData();

          // this.logInCheckFun();
        },
        (error: any) => {
          this.splashScreen.hide();

          this.common.errorHandler(error);
          resolve(error)
        }
      )
    });
  }


  public handleError(error: Response) {

    this.splashScreen.hide();
    this.common.dismissLoaderInit()
    return Observable.throw(error);
  }

  async initializeApp() {
    const appData = await this.appState.getAppData();
    //console.log("devicCheckBase64", appData)
    if (this.platform.is('cordova')) {
      this.encryptiongetInitApp()
    } else {
      await this.encryptiongetInitApp()
    }
  }

  async checkAppStateData() {
    // this.encyptionNewFun()
    var self = this;
    let isLoaded = await this.appState.loadAppData();
    return new Promise((resolve, reject) => {
      if (!isLoaded && !this.ionicStorageData.loginCheck) {
        this.getInitApp('firstTimeUser').subscribe(
          response => {
            if (response) {
              self.appState.saveAppData(response);
              // if( response.collapseAble){
              //   localStorage.setItem(storageKeys.welcomeButtonStatus, response.collapseAble.isSSO)
              // }
              if (this.deiviceStatus == true) {
                // this.checkBuildVersion()
              }
              // this.event.publish('dataLoadinit', '');
              resolve(response);
            }
          },
          error => {
            console.log("InitilaiseApp_error: ", error);
            this.common.errorHandler(error);
          });
      }
      else {
        this.getInitApp('checkUpdatedData').subscribe(
          response => {
            if (response) {
              if (Object.entries(response).length === 0) {
                this.event.publish('dataLoadinit', '');
                resolve(response);
                // //console.log("Blank app init ka data ")
              } else {
                resolve(response);
                // //console.log("New init app data is coming.. ")
                self.appState.saveAppData(response);
                // localStorage.setItem(storageKeys.welcomeButtonStatus, response.collapseAble.isSSO)
                // this.event.publish('dataLoadinit', '');
                let currentTimestamp = new Date().getTime();
                var stringForm = currentTimestamp.toString();
                localStorage.setItem('currentTime', stringForm)
                if (this.deiviceStatus == true) {
                  // this.checkBuildVersion()
                }

              }
            }
          },
          error => {
            this.common.dismissLoaderInit()
            this.common.errorHandler(error);
            //console.log("InitilaiseApp_error: ", error);
          });
        //console.log("InitilaiseApp last console ");
      }
    })
  }

  async checkFirstTimeUserStateData() {
    // this.common.presentLoader()
    var self = this;
    return new Promise((resolve, reject) => {
      this.getInitApp('firstTimeUser').subscribe(
        response => {
          if (response) {
            // //console.log('New Data Update>>>>>', response)
            self.appState.saveAppData(response);
            localStorage.setItem(LOGIN_PAGE.aemUrl,JSON.stringify(response.aemURL))
            resolve(response);
          }
          // this.common.dismissLoaderInit()
        },
        error => {
          // this.common.dismissLoaderInit()
          //console.log("InitilaiseApp_error: ", error);
        });
    })
  }

  afterloginAuthFun() {
    if (localStorage.getItem(LOGIN_PAGE.loggedUser)) {
      this.storageService.checkUserLoged = true
      this.encryptService.checkDecyptFun = true
      // //console.log("this point when reach 2nd checkUpdatedData ")
      // this.authenticatekeyApi()
    }
  }

  // async logInCheckFun() {
  //   console.log("inside logincheck")
  //   if (localStorage.getItem(LOGIN_PAGE.loggedUser)) {
  //     this.common.dashboardApi = true
  //     this.splashScreen.hide();
  //     if (this.common.dashboardApi) {
  //       console.log("inside logincheck2")
  //       this.event.publish('allDashboardApi', '');
  //     }
  //   } 
   
  // }

  checkCSRFTokenInit(): Observable<any> {
    //console.log('checkCSRFToken=======>>>>>>>>>>>')

    const appData = this.appState.getAppData();
    console.log("appData",appData)
    if( appData.devicCheckBase64) {
      this.deviceToken = appData.devicCheckBase64.saveDevicecheckBase64
    }

    let options = new HttpInputData();
    if (this.platform.is("cordova")) { 
      var datapass = {
        "device_token":this.deviceToken
      }
    } else {
      var datapass = {
        "device_token":"asbhdjkhdkjshjdkhskjdhskjd34"
      }
    }

    return this.httpWrapper.postHeader('program/anonymouskey', datapass,options, true)
      .map((res: any) => {
        if (res) {
          localStorage.setItem(storageKeys.anonymousKey,JSON.stringify(res.body))
          this.encryptService.beforesecureKeyValue = res['body'].secureKey;
          this.encryptService.beforesecureIVKeyValue = res['body'].secureIV;
          this.appState.saveCsrfToken(res.headers.get("csrftoken"));
        }
      })
  }

    encyptionNewFun() {
  
    this.encryptService.afterescureKeyValue = JSON.parse(localStorage.getItem(storageKeys.authenticatekey)).secureKey
    this.encryptService.afterescureIVKeyValue = JSON.parse(localStorage.getItem(storageKeys.authenticatekey)).secureIV
    this.encryptService.beforesecureKeyValue = JSON.parse(localStorage.getItem(storageKeys.anonymousKey)).secureKey
    this.encryptService.beforesecureIVKeyValue = JSON.parse(localStorage.getItem(storageKeys.anonymousKey)).secureIV
   
  }


    

    

}
