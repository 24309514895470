import { StorageService } from 'src/app/services/core/storage/storage.service';
import { Injectable } from '@angular/core';
import { storageKeys } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
/**
 * This class loads/save the application data to storage.
 */
export class AppStateService {
  private static appData: any = {};
  token: any;
  constructor(public storageService: StorageService) { }


  public getAppData(): any {
    if (!AppStateService.appData.appInitData) {
      this.loadAppData();
    }
    return AppStateService.appData;
  }

  public async loadAppData() {
    var isLoaded: boolean = false;
    ////console.log("Loaded check======>>>>>>", isLoaded);


    await this.storageService.get(storageKeys.appInitData, storageKeys.anonymousKey).then(res => {

      AppStateService.appData.appInitData = JSON.parse(res);

    }).catch(err => { });



    await this.storageService.get(storageKeys.sessionToken, storageKeys.anonymousKey).then(res => {
      AppStateService.appData.sessionToken = JSON.parse(res);
    }).catch(err => { });

    await this.storageService.get(storageKeys.devicCheckBase64, "").then(res => {
      AppStateService.appData.devicCheckBase64 = JSON.parse(res);
    }).catch(err => { });


    await this.storageService.get(storageKeys.loginCheck, storageKeys.anonymousKey).then(res => {
      AppStateService.appData.loginCheck = JSON.parse(res);
    }).catch(err => { });


    await this.storageService.get(storageKeys.employeeidHeader, storageKeys.anonymousKey).then(res => {
      AppStateService.appData.employeeidHeader = JSON.parse(res);
    }).catch(err => { });

    await this.storageService.get(storageKeys.csrftoken, storageKeys.anonymousKey).then(res => {
      AppStateService.appData.csrftoken = JSON.parse(res);
    }).catch(err => { });


    await this.storageService.get(storageKeys.userPageAccess, storageKeys.anonymousKey).then(res => {
      AppStateService.appData.userPageAccess = JSON.parse(res);
    }).catch(err => { });


    
      await this.storageService.get(storageKeys.userDetails, storageKeys.authenticatekey).then(res => {
        AppStateService.appData.userDetails = JSON.parse(res);
      }).catch(err => { });


      // await this.storageService.get(storageKeys.userPageAccess, storageKeys.authenticatekey).then(res => {
      //   AppStateService.appData.userPageAccess = JSON.parse(res);
      // }).catch(err => { });


      await this.storageService.get(storageKeys.userDetailsForChat, storageKeys.authenticatekey).then(res => {
        AppStateService.appData.userDetailsForChat = JSON.parse(res);
      }).catch(err => { });
    


    if (AppStateService.appData.appInitData) {
      isLoaded = true;
    }
    return isLoaded;
  }

  public async  saveAppData(appInitData: any) {
    AppStateService.appData.appInitData = appInitData;
    await this.storageService.set(storageKeys.appInitData, JSON.stringify(appInitData), storageKeys.anonymousKey);

  }

  public async saveUserDetails(userDetails: any) {
    AppStateService.appData.userDetails = userDetails;
    await this.storageService.set(storageKeys.userDetails, JSON.stringify(userDetails), storageKeys.authenticatekey).then(res => { }).catch(err => { });
  }


  public async saveUserDetailsPageAccess(userPageAccess: any) {
    AppStateService.appData.userPageAccess = userPageAccess;
    await this.storageService.set(storageKeys.userPageAccess, JSON.stringify(userPageAccess), storageKeys.anonymousKey).then(res => { }).catch(err => { });
  }


  public async saveSessionToken(sessionToken: any) {
    AppStateService.appData.sessionToken = sessionToken;
    await this.storageService.set(storageKeys.sessionToken, JSON.stringify(sessionToken), storageKeys.anonymousKey).then(res => { }).catch(err => { });
  }

  public async saveEmployeIdforHeader(employeeidHeader: any) {
    AppStateService.appData.employeeidHeader = employeeidHeader;
    await this.storageService.set(storageKeys.employeeidHeader, JSON.stringify(employeeidHeader), storageKeys.anonymousKey).then(res => {
      ////console.log('saveEmployeeId_Succ ==>',res);
    }).catch(err => {
      ////console.log('saveEmployeeId_Err ==>',err);
    });
  }

  public async saveCsrfToken(csrftoken: any) {
    AppStateService.appData.csrftoken = csrftoken;
    await this.storageService.set(storageKeys.csrftoken, JSON.stringify(csrftoken), storageKeys.anonymousKey).then(res => { }).catch(err => { });
  }

  // public async saveSessionToken(sessionToken:any) {
  //   AppStateService.appData.sessionToken = sessionToken;
  //    await this.storageService.set(storageKeys.sessionToken,sessionToken,storageKeys.authenticatekey);
  // }
  public async saveLoginCheck(loginCheck: any) {
    AppStateService.appData.loginCheck = loginCheck;
    await this.storageService.set(storageKeys.loginCheck, loginCheck, storageKeys.anonymousKey);
  }


  public async saveDeviceCheckBase64(data: any) {
    AppStateService.appData.devicCheckBase64 = data;
    await this.storageService.set(storageKeys.devicCheckBase64, JSON.stringify(data),'').then(res => { }).catch(err => {
    })
  }

  public async saveUserDetailsForChat(userDetailsForChat: any) {
    AppStateService.appData.userDetailsForChat = userDetailsForChat;
    await this.storageService.set(storageKeys.userDetailsForChat, JSON.stringify(userDetailsForChat), storageKeys.authenticatekey).then(res => { }).catch(err => { });
  }


}