import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { Observable } from 'rxjs';
import { LOGIN_PAGE } from '../app.constants';
import { AuthServService } from '../services/auth-serv.service';
import { StorageService } from '../services/core/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  routerUrl: string;
  tokenLocal: string;
  constructor(
    private router: Router, private storage: StorageService, public auth: AuthServService, private splashScreen: SplashScreen,
  ) {

  }



  canActivate(): Observable<boolean> {
    //  console.log("localStorageloggedUser",localStorage.getItem(LOGIN_PAGE.loggedUser))
    this.tokenLocal = localStorage.getItem(LOGIN_PAGE.loggedUser)
    // console.log("tokenLocal",this.tokenLocal)
    if (this.tokenLocal == 'true') {
      return Observable.of(true);

      // return Observable.of(true);
    } else {
      // console.log(" not inside logged")
      return Observable.of(false);
    }

  }


}
