import { Component,  Input,  OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DashboardhomeService } from 'src/app/services/dashboardhome.service';
import { COUNT, JAVASCRIPT } from 'src/app/app.constants';
import { CommonService } from 'src/app/services/common.service';
import { AppStateService } from 'src/app/services/init/app-state.service';
import {KeyValue} from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';
@Component({
  selector: 'app-assign-table',
  templateUrl: './assign-table.component.html',
  styleUrls: ['./assign-table.component.scss'],
})
export class AssignTableComponent implements OnInit {
  @Input() tableSfid: any;
  @Input () outletSfid; 
  @Input() tableType
  tableNumber;
  tableDetails;
  getReservationArrival= "AA";
  startDate: any;
  skelton: boolean = true;
  indexValue: number = Number(COUNT.COUNT_1);
  endDate: any;
  dataset: any;
  awaitData: any;
  NolistMessageArrival :boolean = false
  scroller: false;
  reservId: any;
  reservationTimeArr: any = [];
  ionicStorageEmployeeId: any;
  guestNameArry: any = [];
  checkNo: any
  flag: boolean= false;
  assignTableData :any;
  outletType : any;
  lastRadioclicked : any;
  profileid : any;
  category: string[];
  tags: string[];
  constructor(
    public modalCtrl: ModalController, 
    public dashboardService: DashboardhomeService,
    public common: CommonService,
    public app: AppStateService,
    public router: Router
      ) {
   }

  ngOnInit() { 
   
    this.endDate = new Date().toISOString().split(JAVASCRIPT.T)[COUNT.COUNT_0];
    this.startDate = new Date(Date.now()).toISOString().split(JAVASCRIPT.T)[COUNT.COUNT_0];
    this.tags = this.tableDetails.tags
    
    
  }

  ionViewWillEnter() {
    this.ionicStorageEmployeeId = this.app.getAppData();
    this.getReservationsArrivalApi();
  }


  getReservationsArrivalApi() {
    this.common.presentLoader();
      this.dashboardService.allReservationApi(this.getReservationArrival, this.startDate, this.endDate, JAVASCRIPT.BLANK, JAVASCRIPT.BLANK, this.indexValue, JAVASCRIPT.BLANK).subscribe(res => {
      // this.dashboardService.allReservationApi(this.getReservationArrival, '2020-06-23', '2020-06-23', JAVASCRIPT.BLANK, JAVASCRIPT.BLANK, this.indexValue, JAVASCRIPT.BLANK).subscribe(res => {

      this.skelton = false;
      if (res) {
        this.common.dismissLoader();
        this.dataset = res.isNextDataSet;
        this.awaitData = res.result;
        if (this.awaitData.length === 0) {
          this.NolistMessageArrival = true;
        } 
        this.scroller = res.isNextDataSet;
        for (let i = 0; i < this.awaitData.length; i++) {
          for (let j = 0; j < this.awaitData[i].reservationDetails.length; j++) {
            this.reservId = this.awaitData[i].reservationDetails[j].reservationId
            if (!this.reservationTimeArr.includes(this.awaitData[i].reservationDetails[j].reservationTime))
              this.reservationTimeArr.push(this.awaitData[i].reservationDetails[j].reservationTime);
          }
        }
      }
    }, err => {
      this.common.dismissLoader();
      this.common.errorHandler(err);
    })
  }


  closeModal(tableNumber?, pageName?,) {
    localStorage.setItem('RestaurantView',"false");
    this.modalCtrl.dismiss(tableNumber, pageName);
  }


  selectedUser(data, outletType){
    // if(this.lastRadioclicked != j){

    //  this.profileid = j ;
    // }
    // else {
    //   console.log("else ", j, "this.lastRadioclicked", this.lastRadioclicked );
    //   this.lastRadioclicked = j
    // }
    // this.checkValue = false;
    this.flag= true;
    this.assignTableData = data;
    this.outletType = outletType
    console.log("assignTableData ==>>", this.assignTableData);
    // this.checkValue = true;
  }
  assignTable(){
    this.common.presentLoader();
    localStorage.setItem('RestaurantView',"true");
    this.dashboardService.assignTable(this.tableSfid,this.assignTableData,this.outletSfid).subscribe(res => {
        if (res) {
        
          this.common.dismissLoader();
          this.modalCtrl.dismiss();
          this.common.customAlertReload(res.statusMessage)
        } else {
          this.common.dismissLoader();
        }
      }, err => {
        this.common.dismissLoader();
        this.common.errorHandler(err);
      })
  }

  walkin(){
    console.log("walkin called ", this.tableNumber);
    this.closeModal(this.tableNumber, "walk");
    this.common.tableSfid = this.tableSfid
    
  }

  viewReservation(reservationId) {
    let navigationExtras: NavigationExtras = {
      state: { membershipDetails: JSON.stringify(reservationId.reservationID) }
    }
   
    this.closeModal();
    this.router.navigateByUrl('manage-reservation', navigationExtras);
  }
}
