export class ReservationPayload {
    benefitCode?: string;
    reservationDetails: ReservationDetails;
    memberDetails: MemberDetails;
    guestCount: string;
    adultCount?: string;
    kidsCount?: string;
    foodPreferences?: Array<string>;
    beveragePreferences?: Array<string>;
    specialRequest?: string;
    // isTnCChecked: boolean = true;
    isTnCChecked: string = "true";

    celebration?: Celebration;
    spaTreatment?: string;
    spaDuration?: number;
    childPromotionId?: string;
    promotionOfferId?: string;
    offerText?: string;
    promoCode?: string;
    memberId?: string;
    selectedTablePreference?:string;
    roomNumber? : number


    constructor(payload: PayloadForReservation) {
        this.benefitCode = payload.benefitCode || '';
        this.reservationDetails = new ReservationDetails(payload.reservationDetails);
        this.memberDetails = new MemberDetails(payload.memberDetails);
        this.guestCount = payload.guestCount || '';
        this.adultCount = payload.adultCount || '';
        this.kidsCount = payload.kidsCount || '';
        this.foodPreferences = payload.foodPreferences || [];
        this.beveragePreferences = payload.beveragePreferences || [];
        this.specialRequest = payload.specialRequest || '';
        this.celebration = new Celebration(payload.celebration);
        this.spaTreatment = payload.spaTreatment || '';
        this.spaDuration = payload.spaDuration || 0;
        this.childPromotionId = payload.childPromotionId || '';
        this.promotionOfferId = payload.promotionOfferId || '';
        this.offerText = payload.offerText || '';
        this.promoCode = payload.promoCode || '';
        this.memberId = payload.memberId || '';
        this.selectedTablePreference = payload.selectedTablePreference || '';
        this.roomNumber =  payload.roomNumber  ;
    }

}

export interface PayloadForReservation {
    benefitCode?: string;
    reservationDetails: ReservationDetails;
    memberDetails: MemberDetails;
    guestCount: string;
    adultCount?: string;
    kidsCount?: string;
    foodPreferences?: Array<string>;
    beveragePreferences?: Array<string>;
    selectedTablePreference?: string;
    specialRequest?: string;
    celebration?: Celebration;
    spaTreatment?: string;
    spaDuration?: number;
    childPromotionId?: string;
    promotionOfferId?: string;
    offerText?: string;
    promoCode?: string;
    memberId?: string;
    roomNumber?: any
}


export class ReservationDetails {
    reservationDate: string;
    reservationTime: string;

    constructor(reservationDetails: { reservationDate: string, reservationTime: string }) {
        this.reservationDate = reservationDetails.reservationDate;
        this.reservationTime = reservationDetails.reservationTime
    }
}

export class MemberDetails {
    firstName: string;
    lastName: string;
    honorofic: string;
    mobileNo: number;
    emailId: string;

    constructor(memberDetails: {
        firstName: string,
        lastName: string,
        honorofic: string,
        mobileNo: number,
        emailId: string
    }) {
        this.firstName = memberDetails.firstName,
            this.lastName = memberDetails.lastName,
            this.honorofic = memberDetails.honorofic,
            this.mobileNo = Number(memberDetails.mobileNo) ,
            this.emailId = memberDetails.emailId
    }
}

export class Celebration {
    occasion: string;
    description: string;

    constructor(celebration: {
        occasion: string,
        description: string
    }) {
        this.occasion = celebration && celebration.occasion ? celebration.occasion : '';
        this.description = celebration && celebration.description ? celebration.description : '';
    }
}

export const foodPreferencesPrototype = [
    {
        "id": "Lactose Free",
        "value": "Lactose Free"
    },
    {
        "id": "Vegan",
        "value": "Vegan"
    },
    {
        "id": "Shellfish Free",
        "value": "Shellfish Free"
    }
];


export const beveragePreferencesPrototype = [
    {
        "id": "Champagne / Sparkling",
        "value": "Champagne / Sparkling"
    },
    {
        "id": "Wine",
        "value": "Wine"
    },
    {
        "id": "Liquor",
        "value": "Liquor"
    },
    {
        "id": "Tea",
        "value": "Tea"
    },
    {
        "id": "Coffee",
        "value": "Coffee"
    }
];

export const sampleReservationJSON = {
    // "benefitCode": "",
    "reservationDetails": {
        "reservationDate": "2020-09-20",
        "reservationTime": "14:34"
    },
    "memberDetails": {
        "firstName": "Raja",
        "lastName": "veldandi",
        "honorofic": "Mr",
        "mobileNo": 919617445356,
        "emailId": "velndrirhijwa12@gmail.com"
    },
    "guestCount": "1",
    // "adultCount": "1",
    // "kidsCount": "2",
    // "foodPreferences": [
    //     "Veggies",
    //     "Fruits"
    // ],
    // "beveragePreferences": [
    //     "SoftDrinks"
    // ],
    // "specialRequest": "Neck Pillow",
    "isTnCChecked": "true",
    // "celebration": {
    //     "occasion": "Meeting",
    //     "description": "Get to gether"
    // },
    // "spaTreatment": "",
    // "spaDuration": 0,
    // "childPromotionId": "",
    // "promotionOfferId": "",
    // "offerText": "",
    // "promoCode": "",
    "memberId": "103137"
}